@use '../config/colors' as colors;
@use '../config/mixin' as mixin;

.giftCardDetailsScene{
    justify-content: flex-start;
    .giftCardDetailsContainer{
        min-height: calc(100vh - 70px);
        background-color: colors.$fondGrisClair;
        border-radius: 20px 20px 0 0;
        margin-top: 100px;
        box-shadow: 0 3px 23px 0 rgba(0, 0, 0, 0.3);
        .cardInfo{
            .giftCardItem{
                width: 100%;
                display:flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: flex-start;
                .imageBlock{
                    margin: -90px 0 0 27px;
                    cursor: pointer;
                    img{
                        border-radius: 25px;
                    }
                    .giftCardImage{
                        width: 260px;
                    }
                }
                .contentBlock{
                    .giftCardName{
                        font-size: 28px;
                        font-weight: 500;
                        color: colors.$charcoalGrey;
                        width: 90%;
                        margin: 12px 0 0 20px;
                    }
                    .subLineBlock{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        width: 98%;
                        .amountBlock{
                            width: 45%;
                            margin-left: 20px;
                            .giftCardAmount{
                                margin-top: 10px;
                                text-align: left;
                                display:flex;
                                flex-direction: row;
                                justify-content: flex-start;
                                .borderLine{
                                    height: 80px;
                                    width: 10px;
                                    background-color: colors.$vertSombre;
                                    border-radius: 99px;
                                    margin: 7px 16px 0 0;
                                }
                                .itemContent{
                                    .amountLabel{
                                        font-size: 14px;
                                        font-weight: 400;
                                        color: colors.$textGris;
                                    }
                                    .amountValue{
                                        font-size: 18px;
                                        color: colors.$vertSombre;
                                    }
                                    .giftCardEndDate{
                                        color: colors.$textGris;
                                    }
                                }
                            }
                        }
                        .maxCheckoutAction{
                            width: 48%;
                            margin-top: 24px;
                            .maxCheckoutBtn{
                                box-shadow: 3px 6px 9px 0 rgba(0, 0, 0, 0.21);
                                border-radius: 10px;
                                text-align: center;
                                height: 36px;
                                font-weight: 600;
                            }
                            .orange{
                                background-color: colors.$fondOrangeJaune;
                            }
                            .lightGrey{
                                background-color: colors.$fondGrisMoyen;
                            }
                            a{
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
        }
       .maxCheckoutBlock{
        margin: 20px auto;
        width: 90%;
        background-color: white;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        padding-left: 20px;
        .checkoutFirtsLine{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            margin-top: 20px;
            .maxCheckoutTitle{
                text-align: center;
                color: colors.$charcoalGrey;
                font-size: 18px;
                font-weight: 500;
            }
            .closeBtn{
                margin-right: 20px;
                img{
                    align-self: center;
                }
            }
        }
        .subTitle{
            font-size: 12px;
            text-align: left;
            font-weight: 400;
            line-height: 16px;
            width: 90%;
            margin-top: 10px;
            color: colors.$textGrisFonce;
        }
        .checkoutSlider{
            width: 90%;
            .MuiSlider-thumb {
                height: 27px;
                width: 27px;
                background-color: colors.$vertSombre !important;
            }
            .MuiSlider-track {
                color: colors.$vertSombre !important;
                height: 10px !important;
            }
            .MuiSlider-rail {
                color: colors.$vertSombre !important;
                height: 10px !important;
            }
            .MuiSlider-active{
                color: colors.$vertSombre !important;
                height: 10px !important;
            }
        }
        .valueLine{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 90%;
            margin: 8px 0 20px 0;
            .checkoutField{
                width: 140px;
                background-color: colors.$fondGrisClair;
                color: colors.$textGris;
                font-size: 30px;
                font-weight: 500;
                border-radius: 12px;
                text-align: left;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-left: 14px;
            }
            .defineMaxValueBtn{
                background-color: colors.$fondOrangeJaune;
                border:0;
                width: 150px;
                height: 50px;
                font-size: 18px;
                font-weight: 600;
                box-shadow: 3px 6px 9px 0 rgba(0, 0, 0, 0.21);
                margin-top: 4px;
            }
        }
       }
       .purchaseBlock{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        border-top: solid 1px grey;
        padding-left: 20px;
        .purchaseTitle{
            margin: 20px 0;
            text-align: left;
            color: colors.$charcoalGrey;
            font-size: 19px;
            font-weight: 500;
        }
        .noPurchaseBlock{
            @include mixin.background-sizing('/media/images/giftCard/no_card_dark.svg');
            width: 333px;
            height: 228px;
            margin:30px auto 30px auto;
            .noPurchaseText{
                width: 80%;
                color: colors.$charcoalGrey;
                font-weight:700;
                font-size:18px;
                text-align: center;
                margin: 85px auto 0 auto;
            }
        }
       }
       .lineBreak{
        width: 90%;
        margin: 20px auto;
       }
       .purchaseItem{
         width: 94%;
         display: flex;
         flex-direction: column;
         justify-content:space-between;
         margin-bottom: 14px;
         .purchaseContainer{
            background-color: white;
            border-radius: 10px;
            padding: 12px;
            .retailerBlock{
                width: 96%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                .retailerName{
                    font-size: 14px;
                    font-weight: 600;
                    margin-bottom: 3px;
                    color: colors.$vertMoyenSombre;
                    line-height: 20px;
                }
                .purchaseTotal{
                    font-weight: 900;
                    color: colors.$vertMoyenSombre;
                    font-size: 16px;
                    line-height: 14px;
                    .big{
                        font-size: 1.1em;
                    }
                    .small{
                        font-size: 0.8em;
                    }
                    .euroSymbol{
                        font-weight: 900;
                        font-size: 1.1em;
                    }
                }
            }
            .purchaseDetails{
                width: 96%;
                .productLineContainer{
                    display:flex;
                    flex-direction: row;
                    justify-content: space-between;
                    color: colors.$textGris;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                    .lineBlock{
                        flex-grow: 2;
                        height: 9px;
                        border-bottom: solid 1px colors.$textGrisClair;
                        margin: 0 6px;
                    }
                    .big{
                        font-size: 1.1em;
                    }
                    .small{
                        font-size: 0.9em;
                    }
                    .euroSymbol{
                        font-weight: 900;
                        font-size: 1.1em;
                    }
                }
            }
         }
         .purchaseDate{
            font-size: 14px;
            line-height: 13px;
            font-weight: 300;
            color: colors.$textGris;
            margin: 8px 6px 0 0;
            text-align: right;
        }
       }
    }
}

@media screen and (max-width:380px){
    .giftCardDetailsScene{
        .giftCardDetailsContainer{
            .cardInfo{
                .giftCardItem{
                    .contentBlock{
                        .subLineBlock{
                            .amountBlock{
                                .giftCardAmount{
                                    .itemContent{
                                        .amountValue{
                                            font-size: 16px;

                                        }
                                        .giftCardEndDate{
                                            font-size: 14px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width:320px){
    .giftCardDetailsScene{
        .giftCardDetailsContainer{
            .cardInfo{
                .giftCardItem{
                    .contentBlock{
                        .subLineBlock{
                            .amountBlock{
                                .giftCardAmount{
                                    .itemContent{
                                        .amountValue{
                                            font-size: 14px;

                                        }
                                        .giftCardEndDate{
                                            font-size: 12px;
                                        }
                                    }
                                }
                            }
                            .maxCheckoutAction{
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}