@use '../config/colors' as colors;
@use '../config/mixin' as mixin;

.didacticielScene{
    margin:auto;
    .leftAlign{
        text-align: left;
    }
    .casherBlock{
        width: 90%;
        height:254px; /// Image size
        margin-left : 20px;
        border-bottom: solid 4px colors.$vertSombre;
        .casherImage{
            margin: 0;
            padding: 0;
            border: 0;
        }
    }

    .faqContainer{
        width: 90%;
        margin: auto;
        font-family: 'Source Sans Pro', sans-serif;
        .Collapsible{
            padding:20px 0 20px 0;
            border-bottom: solid 4px colors.$vertSombre;
            cursor: pointer;
            .faqTitle{
                font-size: 1.4em;
                font-weight: 600;
                color: colors.$vertClair;
            }
            .titleClose{
                background: url('/media/images/didacticiel/fleche_open.png') 3px 5px no-repeat;
                padding: 0 0 0 26px;
            }
            $maxWidth: calc(100% - 10px); 
            .titleOpen{
                background: url('/media/images/didacticiel/fleche_close.png') $maxWidth 5px no-repeat;
                padding: 0 36px 0 0;
            }
            .faqDescription{
                margin-top: 8px;
                font-size: 1.1em;
                font-weight: 500;
                color: colors.$textGrisFonce;
                line-height: 1.3em;
                ul{
                    padding-inline-start: 0;
                    list-style-type: none;
                    li{
                        padding-inline-start: 20px;
                        margin-bottom: 6px;
                        .listItem{
                            color: colors.$vertClair;
                            font-size: 1.2em;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
        
    }

    .slickContainer{
        margin: 20px 0 10px 0;
        overflow: hidden;
        .slideItem{
            .slideTitle{
                width: 90%;
                font-size: 1.4em;
                font-weight: 600;
                color: colors.$vertClair;
                margin:auto;
                text-align: center;
            }
            .slideImage{
                margin: auto;
            }
            .slide01{
                @include mixin.background-sizing('/media/images/didacticiel/didacticiel-01.jpg');
                width: 398px;
                height: 458px;
            }
            .slide02{
                @include mixin.background-sizing('/media/images/didacticiel/didacticiel-02.jpg');
                width: 398px;
                height: 458px;
            }
            .slide03{
                @include mixin.background-sizing('/media/images/didacticiel/didacticiel-03.jpg');
                width: 398px;
                height: 458px;
            }
            .slide04{
                @include mixin.background-sizing('/media/images/didacticiel/didacticiel-04.jpg');
                width: 398px;
                height: 458px;
            }
        }
        .slick-dots{
            bottom: 5px;
            li button:before {
                content: ' \25CF';
                font-size: 36px;
                color: colors.$vertSombre;
                cursor: pointer;
            }
        }
    }
}

@media screen and (max-width:380px) {
    .didacticielScene{
        .slickContainer{
            margin: 10px 0 8px 0;
            .slideItem{
                .slide01{
                    width: 300px;
                    height:345px;
                    background-size: contain;
                }
                .slide02{
                    width: 300px;
                    height:345px;
                    background-size: contain;
                }
                .slide03{
                    width: 300px;
                    height:345px;
                    background-size: contain;
                }
                .slide04{
                    width: 300px;
                    height:345px;
                    background-size: contain;
                }
            }
            
        }
    }
}

@media screen and (max-width:320px) {
    .didacticielScene{
        .slickContainer{
            margin: 10px 0 8px 0;
            .slideItem{
                .slide01{
                    width: 300px;
                    height:345px;
                    background-size: contain;
                }
                .slide02{
                    width: 300px;
                    height:345px;
                    background-size: contain;
                }
                .slide03{
                    width: 300px;
                    height:345px;
                    background-size: contain;
                }
                .slide04{
                    width: 300px;
                    height:345px;
                    background-size: contain;
                }
            }
            
        }
    }
}