.askPasswordScene{
    margin:auto;
    display:flex;
    flex-direction: column;
    .formContainer{
        margin-top: 30px;
        height: calc(100vh - 230px); // Header + login footer
        display:flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
}