@use '../config/colors' as colors;
@use '../config/mixin' as mixin;

.retailersList{
    width: 100%;
    max-width: 1000px;
    background-color: white;
    margin: auto;
    z-index: 2;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    padding-top: 20px;
    .retailerItem{
        border-bottom: solid 1px #e6e6e6;
        cursor: pointer;
        width: 100%;
        .retailerInfo{
            display:flex;
            margin: 20px 0 20px 13px;
            justify-content: space-between;
            .brand{
                width:23px;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                margin-top: 14px;
                .retailerImage{
                    width: 23px;
                }
            }
            .retailerData{
                flex-grow: 2;
                width:45%;
                margin: 10px 10px 0 10px;
                .retailerName{
                    font-size: 19px;
                    font-weight: 600;
                    margin-bottom: 3px;
                    color: colors.$vertMoyenSombre;
                    line-height: 20px;
                }
                .retailerAddress{
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: 500;
                    color: colors.$textGris;
                    width: 80%;
                }
                .retailerUrl{
                    margin-top:6px;
                    font-size: 0.9em;
                    font-weight: 500;
                    color: colors.$vertSombre;
                }
                .clickCollect{
                    width: 195px;
                    height: 39px;
                    background-color: colors.$bleuClair;
                    border-radius: 9px;
                    color: white;
                    font-size: 13px;
                    font-weight: 600;
                    display:flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    margin-top: 20px;
                    .clickCollectIcon{
                        background-image: image-set(
                            url("/media/images/map/bt_clickCollect.png") 1x,
                            url("/media/images/map/bt_clickCollect@2x.png") 2x,
                            url("/media/images/map/bt_clickCollect@3x.png") 3x
                        );
                        margin: 6px 10px 0 11px;
                        width: 39px;
                        height: 25px;
                    }
                }
                .shipping{
                    width: 195px;
                    height: 39px;
                    background-color: white;
                    border: solid 1px #d5d5d5;
                    border-radius: 9px;
                    color: colors.$textGris;
                    font-size: 13px;
                    font-weight: 500;
                    display:flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    margin-top: 20px;
                    .shippingIcon{
                        background-image: image-set(
                            url("/media/images/map/ico_delivery_truck.png") 1x,
                            url("/media/images/map/ico_delivery_truck@2x.png") 2x,
                            url("/media/images/map/ico_delivery_truck@3x.png") 3x
                        );
                        margin: 2px 10px 0 11px;
                        width: 26px;
                        height: 14px;
                    }
                }
            }
            .total{
                width:114px;
                min-height: 82px;
                font-weight: 900;
                line-height: 13px;
                color: white;
                text-align: center;
                @include mixin.background-no-sizing-svg("/media/images/map/bg_coupon.svg");
                display: flex;
                flex-direction: column;
                justify-content: center;
                .amount{
                    margin-top: 5px;
                }
                .discountsText{
                    font-size: 11px;
                    text-align: center;
                    margin-top: 7px;
                }
            }
            .arrowContainer{
                width: 36px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .arrow{
                    @include mixin.background-sizing-svg("/media/images/map/ico_voirplus.svg");
                    width: 27px;
                    height: 27px;
                }
            }
        }
    }
    .noResults{
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        color: colors.$textGris;
        margin: 10px 0 30px 0;
    }
}

@media screen and (max-width:420px) {
    .retailersList{
        max-width: 100%;
        .retailerItem{
            .retailerInfo{
                .retailerData{
                    .clickCollect{
                        width: 165px;
                    }
                    .shipping{
                        width: 165px;
                    }
                }
                .arrowContainer{
                    width: 22px;
                    .arrow{
                        width: 17px;
                        height: 17px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:320px) {
    .retailersList{
        max-width: 100%;
        .retailerItem{
            .retailerInfo{
                .retailerData{
                    .retailerName{
                        font-size: 18px;
                    }
                    .clickCollect{
                        width: 145px;
                    }
                    .shipping{
                        width: 145px;
                    }
                }
                .total{
                    width: 94px;
                    font-size: 12px;
                    border-radius: 0 12px 12px 0;
                }
                .arrowContainer{
                    width: 22px;
                    .arrow{
                        width: 17px;
                        height: 17px;
                    }
                }
            }
        }
    }
}