@use '../config/colors' as colors;

$maxWidth: 420px;

.stampListDetailsScene{
    justify-content: flex-start;
    .stampListDetailsContainer{
        display:flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color: colors.$fondGrisClair;
        border-radius: 0 0 20px 20px;
        .stampHeader{
            text-align: center;
            .listBaseline{
                font-size: 14px;
                font-weight: 400;
            }
        }
        .stampSceneTitle{
            font-size: 24px;
            font-weight: 600;
            color: colors.$titreOrange;
            margin: 40px auto 0 auto;
        }
        .retailerDetailsBlock{
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 22px auto 26px auto;
            border-radius: 20px;
            background-color: white;
            width: 90%;
            height: 163px;
            .detailsProduct{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 107px;
                border: solid 1px colors.$textGrisClair;
                border-radius: 20px;
                width:127px;
                height: 145px;
                margin-left: 11px;
                .productImage{
                    height: 100px;
                }
            }
            .detailsName{
                display: flex;
                flex-direction: column;
                justify-content: center;
                flex-grow: 2;
                width: 50%;
                .fidelityCampaignNameContent{
                    width:80%;
                    text-align: left;
                    font-size: 20px;
                    line-height: 23px;
                    font-weight: 600;
                    color: colors.$textGrisFonce;
                    margin: 12px 0 20px 20px;
                }
            }
            .retailerDetails{
                display: flex;
                min-height: 60px;
                align-items: center;
                border-top: solid 1px colors.$textGrisClair;
                .geolocPic{
                    width: 18px;
                    height: 26px;
                    margin-left: 20px;
                    svg{
                        path{
                            fill: colors.$vertSombre;
                            fill-rule:evenodd;
                            opacity:.8
                        }
                    }
                }
                .detailsBlock{
                    margin-left: 11px;
                    .retailerName{
                        font-size: 15px;
                        font-weight: 600;
                        line-height: 15px;
                        color: colors.$vertSombre;
                    }
                    .retailerCity{
                        font-size: 11px;
                        line-height: 15px;
                        font-weight: 600;
                        color: colors.$textGrisFonce;
                    }
                }
            }
        }
        .stampContainer{
            width: 96%;
            max-width: $maxWidth;
            margin: 20px auto;
            .stampBlock{
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(116px, 1fr));
                grid-auto-rows: auto;
                grid-gap: 10px;
                margin: 0 auto 40px auto;
                .stampItem{
                    border-radius: 20px;
                    background-color: white;
                    width: 116px;
                    height:116px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    text-align: center;
                    justify-self: center;
                    .itemContainer{
                        position: relative;
                        svg{
                            position:absolute;
                            z-index: 2;
                            right:-13px;
                            bottom:-32px;
                            width: 60px;
                            height: 60px;
                            path{
                                fill: colors.$vertSombre;
                            }
                        }
                        .imgItem{
                            height: 90px;
                        }
                        .product{
                            opacity: 0.4;
                        }
                    }
                }
            }
            
            .couponBlock{
                width: 90%;
                min-height:170px;
                max-width: $maxWidth;
                background-color: white;
                border-radius: 20px;
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
                margin: 12px auto;
                .couponHeader{
                    border-radius: 20px 20px 0 0;
                }
                .hasCoupon{
                    background-color: colors.$vertSombre;
                    height: 50px;
                }
                .noCoupon{
                    background-color: #c2c2c2;
                    height: 34px;
                }
                .equalContainer{
                    background-color: white;
                    border-radius: 50%;
                    width: 38px;
                    height: 38px;
                    border: solid 3px colors.$vertSombre;
                    align-self: center;
                    margin-top: -40px;
                    .equalSign{
                        text-align: center;
                        font-size: 40px;
                        line-height: 33px;
                        font-weight: 700;
                        color: colors.$vertSombre;
                    }
                }
                .offertContainer{
                    align-self: center;
                    margin-top: -40px;
                    display: flex;
                    .offertSign{
                        color: white;
                        font-size: 13px;
                        font-weight: 700;
                        align-self: center;
                        svg{
                            width: 34px;
                            height: 34px;
                            vertical-align: middle;
                            path{
                                fill: colors.$vertSombre;
                            }
                        }
                    }
                }
                .isUsedContainer{
                    align-self: center;
                    margin-top: -24px;
                    display: flex;
                    color: white;
                    font-size: 13px;
                    font-weight: 700;
                }
                .couponLine{
                    display: flex;
                    .couponImage{
                        margin: 20px 0 0 23px;
                    }
                    .couponDescription{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                        width: 60%;
                        margin-left: 12px;
                        .cgv{
                            color: colors.$vertSombre;
                            font-size: 11px;
                            line-height: 12px;
                            font-weight: 300;
                            text-decoration: underline;
                        }
                    }
                }
            }
            .isUsedIcon{
                display: flex;
                justify-content: center;
                margin-top: -50px;
                svg{
                    .ico_bg{
                        fill: white;
                    }
                    path{
                        
                        fill: colors.$textGrisMoyen;
                    }
                }
            }
        }
    }
}

@media screen and (min-width:421px) {
    .stampListDetailsScene{
        .stampListDetailsContainer{
            .stampContainer{
                width: 90%;
                max-width: 100vw;
            }
        }
    }
}