@use '../config/colors' as colors;

#CybotCookiebotDialog { 
    font-family: 'Ubuntu' !important;
    #CybotCookiebotDialogPoweredbyLink{
        display: none;
    }
}
#CybotCookiebotDialogBodyContentTitle{
    font-family: "Ubuntu"!important;
}
#CybotCookiebotDialogBodyContentText{
    font-family: "Ubuntu"!important;
}
a#CybotCookiebotDialogBodyLevelButtonAccept, 
a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll,
a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection{
    background-color: colors.$titreOrange !important;
    border: none !important;
    border-radius: 50px 50px 50px 0 !important;
    height: 20px !important;
    font-family: "Ubuntu"!important;
    padding-top: 6px !important;
    width: 220px !important;
}
a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection{
    background-color: colors.$titreOrange!important;
}
a#CybotCookiebotDialogBodyLevelButtonLevelOptinDeclineAll{
    background-color: colors.$titreOrange !important;
    border: none !important;
    border-radius: 50px 50px 50px 0 !important;
    height: 20px !important;
    font-family: "Ubuntu"!important;
    padding-top: 6px !important;
    width: 220px !important;
}
.CookieDeclaration{
    display:none;
}

#CybotCookiebotDialogNav{
    .active{
        color: colors.$vertSombre !important;
        border-bottom: 1px solid colors.$vertSombre !important;
    }
    .CybotCookiebotDialogNavItemLink{
        font-size: 13px !important;
    }
}
#CybotCookiebotDialog *, 
#CybotCookiebotDialogBodyUnderlay *{
    font-size: 13px !important;
    .CybotCookiebotDialogDetailBodyContentCookieContainerButton{
        color: colors.$vertSombre !important;
    }
}

#CybotCookiebotDialogBodyButtonsWrapper{
    .CybotCookiebotDialogBodyButton{
        background-color: colors.$titreOrange !important;
        border: none !important;
        border-radius: 50px 50px 50px 0 !important;
        height: 30px !important;
        font-family: "Ubuntu"!important;
        padding-top: 6px !important;
        width: 100% !important;
        margin: 8px auto  !important;
        color: white !important;
    }
}

#CybotCookiebotDialogBodyEdgeMoreDetailsLink{
    color: colors.$vertSombre !important;
}
.CybotCookiebotDialogActive{
    color: colors.$vertSombre !important;
}