.searchBar{
    background-color: white;
    width: 336px;
    height: 35px;
    box-shadow: 1px 3px 12px 0 rgba(0, 0, 0, 0.31);
    z-index: 7;
    border-radius: 40px;
    margin: 11px auto 20px auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    visibility: hidden;
    .searchField{
        width: 90%;
        justify-content: center;
        margin: 5px 0 0 20px;
    }
}

@media screen and (max-width:320px) {
    .searchBar{
        width: 296px;
    }
}