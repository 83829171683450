@use '../config/colors' as colors;
@use '../config/mixin' as mixin;

.dedicatedListCouponsScene{
    margin:auto;
    display:flex;
    flex-direction: column;
    //@include backgroundScene();
    background-color: white;
    .titleRubrique{
        font-size: 2em;
        color: colors.$vertSombre;
        width: 70%;
    }
    .couponList{
        margin: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-content: center;
        flex-grow: 2;
        margin-bottom: 17px;
        .dedicatedContainer{
            margin-top: 12px;
            .coupon{
                border-bottom: none;
                border-radius: 22px;
                box-shadow: 1px 3px 12px 0 rgba(0, 0, 0, 0.31);
                .dedicatedCoupon{
                    border-radius: 0 0 22px 22px;
                }
            }
        }
        .generalContainer{
            .paypsTitle{
                font-size: 2em;
                font-weight: 700;
                color: colors.$vertSombre;
                width: 80%;
                margin: 36px auto 20px auto;
                text-align: center;
            }
            .generalList{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-evenly;
                align-content: center;
                flex-grow: 2;
                border-top: 1px solid grey;
            }
            
        }
       
    }
    .moreCoupon{
        @include mixin.background-sizing('/media/images/cart/bonDeReduction-new.png');
        width: 376px;
        opacity: 0.7;
        .textBientot{
            margin: 35px;
            font-size: 1.4em;
            font-weight: 700;
            color: colors.$vertClair;
            text-align: center;
        }
    }
}

@media screen and (max-width:1024px) {
    .dedicatedListCouponsScene{
        background-size: cover;
        background-position: 50% 50%;
    }
}

@media screen and (max-width:902px) {
    .dedicatedListCouponsScene{
        .couponList{
            margin-bottom: 0px;
        }
    }
}


@media screen and (max-width:420px) {
    .dedicatedListCouponsScene{
        background-image: none;
        .couponList{
            .moreCoupon{
                display: none;
            }
        }
    }
}

@media screen and (max-width:320px) {
    .dedicatedListCouponsScene{
        .couponList{
            margin: auto;
        }
        
    }
}