@use '../config/colors' as colors;
@use '../config/mixin' as mixin;

.checkProductScene{
    margin:auto;
    @include mixin.backgroundScene();
    .checkProductContainer{
        margin:30px auto 0 auto;
        display:flex;
        flex-direction: column;
        justify-content: space-evenly;
        .useDescription{
            width: 70%;
            font-size: 16px;
            font-weight: 600;
            color: rgba(70, 70, 70, 0.8);
            margin: 0 auto 20px auto;
            text-align: center;
        }
        .viewport{
            margin: auto;
            overflow: hidden;
            .drawingBuffer{
                display: none;
                width: 100vw;
            }
            .productResponse{
                font-size: 1.4em;
                font-weight: 600;
                color: white;
                position: fixed;
                top: 42%;
                left: 0;
                right: 0;
                width: 260px;
                margin: auto;
                text-align: center;
                .responseIcon{
                    display: block;
                    font-size: xxx-large;
                    margin: auto;
                }
                .green{
                    color: green;
                }
                .red{
                    color: red;
                }
            }
        }
        .inputBarcode{
            background-color: white;
        }
        .verifierBtn{
            width: 200px;
            height: 20px;
            margin: 10px auto 0 auto;
        }
    }
}
