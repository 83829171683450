@use '../config/colors' as colors;
@use '../config/mixin' as mixin;

.registrationNeededScene{
    margin:auto;
    min-height: calc(100vh - 70px); // total height -  header
    background-color: white;
    .message{
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        width: 500px;
        margin:auto;
        margin-top:40px;
        font-size: 21px;
        font-weight: 700;
        a{
            text-decoration: none;
        }
        .message_text{
            margin: auto;
            width: 70%;
            text-align: center;
            color: colors.$vertSombre;
        }
        .bottomContainer{
            display:flex;
            flex-direction: row;
            justify-content: space-evenly;
            .connectButtons{
                font-weight:700;
                font-size: 1em;
                text-transform: uppercase;
                color: white;
                text-align: center;
                margin-top: 20px;
                width: 70%;
                order: 2;
                align-self: center;
                .or{
                    margin:15px auto 15px auto;
                    font-size: 0.6em;
                    background-color: colors.$vertClair;
                    width: 38px;
                    height: 38px;
                    line-height: 38px;
                    border-radius: 50%;
                }
                .inscriptionBtn{
                    background-color: colors.$fondOrangeJaune;
                    box-shadow: 3px 6px 9px 0 rgba(0, 0, 0, 0.21);
                }
                .identificationBtn{
                    background-color: colors.$fondOrangeJaune;
                    box-shadow: 3px 6px 9px 0 rgba(0, 0, 0, 0.21);
                }
            }
        }
    }
    .clientBloc{
        width: 900px;
        height: 450px;
        margin: 30px auto 120px auto;
        border-radius: 34px;
        background-image: linear-gradient(179deg, #19806f 42%, #08b297 91%, #00ccac 119%);
        .lines{ 
            @include mixin.background-sizing-svg("/media/images/registration/bg_illustr.svg");
            background-size: contain;
            .clientTitle{
                font-size: 27px;
                font-weight: 900;
                width: 257px;
                text-align: center;
                color: white;
                margin: 36px auto 36px auto;
            }
            .clientImage{
                @include mixin.background-sizing("/media/images/registration/illust_perso.png");
                width: 243px;
                height: 383px;
                margin: auto;
            }
        }
    }
}

@media screen and (max-width:980px) {
    .registrationNeededScene{
        .clientBloc{
            width: 392px;
            height: 439px;
            .lines{ 
                background-size: cover;
                background-position: -330px -78px;
            }
        }
    }
}

@media screen and (max-width:500px) {
    .registrationNeededScene{
        .message{
            width: 100%;
        }
        .clientBloc{
            width: 86%;
            .lines{
                background-position: -330px -73px;
                .clientTitle{
                    font-size: 24px;
                }
            }
        }
    }
}