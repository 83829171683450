@use '../config/colors' as colors;

.legalsFooter{
    min-height:60px;
    background: colors.$vertMoyenClair;
    font-weight: 700;
    color: white;
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 8px 0 8px 0;
    .footerLink{
        text-decoration: underline;
    }
    a{
        line-height: 1.6em;
    }
}
.legalsMenu{
    color: white;
    font-size: 0.9em;
    line-height: 1em;
    display:flex;
    flex-direction: column;
    font-weight: 600;
    margin-bottom: 16px;
    .footerLink{
        text-decoration: underline;
    }
    a{
        line-height: 1.3em;
    }
}

@media screen and (max-width:699px) {
    .legalsFooter{
        flex-direction: column;
        span{
            margin-left: 13%;
            border-left: solid 2px white;
            padding-left: 10px;
        }
    }
}