
@use '../config/colors' as colors;
@use '../config/mixin' as mixin;

.desktopRs{
    display: none !important;
    margin: auto;
    width: 250px;
}

 .paypsRs{
    font-size: 1em;
    color: colors.$vertClair;
    font-weight: 400;
    margin-bottom: 30px;
    display: block;
    .rsRow{
      display:flex;
      flex-direction: row;
      justify-content: space-between;
      width: 40%;
      margin-top:8px;
      cursor: pointer;
      .menuFacebook{
        @include mixin.background-sizing("/media/images/menu/menuFacebook.png");
        width: 10px;
        height: 19px;
      }
      .menuTwitter{
        @include mixin.background-sizing("/media/images/menu/menuTwitter.png");
        width: 19px;
        height: 15px;
      }
      .menuInstagram{
        @include mixin.background-sizing("/media/images/menu/menuInstagram.png");
        width: 17px;
        height: 17px;
      }
      .menuEmail{
        @include mixin.background-sizing("/media/images/menu/menuEmail.png");
        width: 17px;
        height: 17px;
      }
    }
    .version{
      font-size: 0.8em;
    }
  }

  @media screen and (max-width:420px) {
    .desktopRs{
        display: none;
    }
    .mobileRs{
        display: block !important;
    }
    .paypsRs{
      font-size: 1em;
      color: colors.$vertClair;
      font-weight: 400;
      margin-bottom: 30px;
      display: block;
      .version{
        font-size: 0.8em;
        margin-top: 6px;
      }
    }
  }