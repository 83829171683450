$vertTresSombre: #036455;
$vertMoyenSombre: #19806f;
$vertSombre:#187D6E;
$vertMoyen: #1A9580;
$vertMoyenClair: #45b7a3;
$vertClair: rgb(33, 202, 171);
$vertTresClair:rgb(180, 239, 231);


$textGrisClair:#BCBCBC;
$textGrisFonce:#474747;
$textGrisMoyen:#75725E;
$textGris: #8c8c8c;
$textGrisTresClair: #d2d2d2;
$charcoalGrey: rgba(71, 71, 72, 0.87);
$textNoir: #2F2F2F;
$textRouge: #DA1414;

$facebookBlue:rgb(61, 92, 150);
$googleRed:rgb(233, 68, 61);

$fondOrange: #FBEEE8;
$titreOrange: #F5A528;
$fondOrangeJaune: #f5c04b;
$fondOrangeClair: #FAD185;
$fondOrangeMoyen: #f7a600;
$fondOrangeFonce: #FD8524;
$ligneGrise: #EBF4F3;
$fondGris: #e8e8e8;
$fondGrisClair: #efefef;
$fondGrisMoyen: #cbcbcb;
$fondGrisSombre: #909090;
$orangeShadow: rgba(181, 133, 35, 0.54);
$fondRose: rgba(242, 232, 227, 1);

$bleuClair: #56c4ed;