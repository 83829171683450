@use '../config/colors' as colors;

.errorScene{
    margin:auto;
    display:flex;
    flex-direction: column;
    justify-content: space-around;
    min-height: 100vh;
    background-color: white;
    .errorImage{
        width: 187px;
        height: 228px;
        margin: 40px 0 40px calc(50% - 114px);
    }
    .errorText{
        flex-grow: 2;
        font-size: 1.5em;
        color: colors.$textRouge;
        font-weight: 700;
        width: 80%;
        margin: auto;
        text-align: center;
        .errorTitle{
            font-size: 2em;
        }
    }
}