@use '../config/colors' as colors;

.activateGiftCardPop{
    justify-content: flex-start;
    width:100%;
    height: 100%;
    background-color:rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
    .activateGiftCardContainer{
        background-color: white;
        width: 90%;
        background-color: white;
        border-radius: 20px;
        position: fixed;
        top: 20%;
        left:5%;
        display:flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding-bottom: 30px;
        .closeBtn{
            align-self: flex-end;
            margin: 12px 20px 0 0;
            .closeImg{
                filter: invert(35%) sepia(98%) saturate(351%) hue-rotate(122deg) brightness(92%) contrast(90%);
            }
        }
        .activateTitle{
            font-size: 18px;
            font-weight: 500;
            color: colors.$charcoalGrey;
            margin: 10px auto 0 auto;
            width: 70%;
            text-align: center;
        }
        .formContainer{
            width: 90%;
            margin: 30px auto 0 auto;
            input{
                font-size: 1em;
            }
            .paypsAccountLabel{
                font-size: 18px;
                font-weight: 400;
                color: colors.$textGrisFonce;
                margin: 30px 0 16px 0;
                text-align: left;
                width: 100vw;
            }
            .hasAccountRadio{
                span{
                    font-family: 'Ubuntu' !important;
                    font-weight: 400;
                    font-size: 1.3em !important;
                    color: colors.$textGrisFonce;
                    svg{
                        color: colors.$vertMoyen;
                    }
                }
            }
            .activateCardBtn{
                background-color: colors.$fondOrangeJaune;
                border: 0;
                margin: 20px auto;
                font-size: 20px;
                font-weight: 700;
                text-transform: uppercase;
                width: 80%;
            }
        }
    }
}