@use '../config/colors' as colors;
@use '../config/mixin' as mixin;

.economyFooter{
    width: 100%;
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
    .orangeBg{
        height: 63px;
        background-color: colors.$fondOrangeMoyen;
        margin-top: 41px;
        flex-grow: 2;
    }
    .footerBg{
        font-family: 'Ubuntu', sans-serif;
        width:100%;
        max-width: 600px;
        height: 104px;
        z-index: 3;
        color: white;
        @include mixin.background-sizing-svg("/media/images/common/footer-bg.svg");
        .topLine{
            display:flex;
            flex-direction: row;
            justify-content: space-evenly;
            .texteEconomy{
                text-transform: uppercase;
                margin-top: 50px; 
                font-weight: 600;
                font-size: 20px;
            }
            .valueEconomy{
                font-size: 20px;
                font-weight: 700;
                margin-top: 23px;
                text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
            }
        }
        .globalEconomy{
            font-weight: 600;
            text-align: center;
            font-stretch: normal;
            font-style: normal;
            .totalEconomyBloc{
                font-size: 18px;
            }
        }
    }
}
.animating{
    position: fixed;
    bottom: -100px;
}
@media screen and (max-width:600px) {
.economyFooter{
    .orangeBg{
        margin-top: 28px;
    }
    .footerBg{
        height: 90px;
        .topLine{
            .texteEconomy{
                margin-top: 36px; 
                font-size: 16px;
            }
            .valueEconomy{
                margin-top: 12px;
                font-size: 16px;
            }
        }
    }
}
}

@media screen and (max-width:360px) {
.economyFooter{
        .footerBg{
            .topLine{
                .texteEconomy{
                    font-size: 16px;
                }
                .valueEconomy{
                    font-size: 16px;
                }
            }
        }
    }
}
@media screen and (max-width:320px) {
.economyFooter{
        .footerBg{
            //height: 89px;
            .topLine{
                .texteEconomy{
                    font-size: 15px;
                }
                .valueEconomy{
                    font-size: 15px;
                }
            }
        }
    }
}