@use '../config/colors' as colors;

.loginFooter{
    overflow: hidden;
    height:130px;
    width:100%;
    .bottomWave{
        width: 140%;
        height: 1200px;
        background: colors.$vertMoyenClair;
        border-radius: 400px / 400px;
        margin-top: 30px;
        margin-left:-20%;
        .identifyBlock {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content:flex-end;
            height: 90px;
            text-align:center;
            .textFooter{
                color:white;
                line-height: 1.5em;
                a,
                a:active{
                    color: white;
                    font-weight:700;
                }
                a:hover{
                    text-decoration: none;
                }
            }
        }
    }
}