@use '../config/colors' as colors;
@use '../config/mixin' as mixin;

.headerPayps{
    position: fixed;
    top:-70px;
    width: 100vw;
    height: 70px;
    margin-top:70px;
    background-color: rgb(24, 125, 110);
    z-index: 10;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .logoContainer{
        margin-top: 14px;
        cursor: pointer;
    }
}

.fakeHeader{
  position: fixed;
  top:0;
  width: 100%;
  height: 70px;
  background-color: rgb(24, 125, 110);
  z-index: 10;
  display:flex;
  .logoContainer{
      margin: 14px auto 0 auto;
      cursor: pointer;
  }
}

  
// Styles de la sideBar
.bm-burger-button{
  width:31px;
  height:25px;
  button{
    width:30% !important;
  }
}

  .bm-item {
    display: inline-block;
    text-decoration: none;
    margin-bottom: 24px;
    color: white;
    outline: 0;
  }
  
  /* Change color on hover */
  .bm-item:hover {
    color: white;
  }
  
  /* Position and sizing of burger button */
  .bm-burger-button {
    width: 36px;
    height: 30px;
    margin-left: 12px;
    margin-top: 21px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: colors.$vertClair;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: colors.$vertClair;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: colors.$vertSombre;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    width:110%;
    display:flex;
    flex-direction: column;
    //justify-content: space-between;
    justify-content:flex-start;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    margin-top: -21px;
  }
  .defaultMenuLink{
      cursor: pointer;
  }

  .menuUserItem{
    margin-bottom: 30px;
    height:53px;
    margin-left:-10%;
    margin-top:-16%;
    background-color: colors.$vertMoyen;
    overflow: hidden;
    padding-top:30px;
    padding-left:30px;
    .blocNom{
      font-weight: 700;
      font-size: 1em;
      color: white;
    }
    .blocEmail{
      font-weight: 400;
      font-size: 1em;
      color: colors.$vertClair;
    }
  }
  .listMenu{
    display: flex !important;
    flex-direction: column;
    height: 70%;

     //// ELEMENTS DU MENU LATERAL
    .menuListBr{
      @include mixin.background-menu("/media/images/menu/ico_coupon.svg");
    }
    .menuListOffers{
      @include mixin.background-menu("/media/images/menu/offresAutourDeMoi.png");
    }
    .menuListRetailers{
      @include mixin.background-menu("/media/images/menu/magasins.png");
    }
    .menuUserAccount{
      @include mixin.background-menu("/media/images/menu/monCompte.png");
    }
    .menuUserDashboard{
      @include mixin.background-menu("/media/images/menu/tableauDeBord.png");
    }
    .menuHowItWorks{
      @include mixin.background-menu("/media/images/menu/commentCaMarche.png");
    }
    .menuCarteClub{
      @include mixin.background-menu("/media/images/menu/ico_carteClub.svg");
    }
    .menuContact{
      @include mixin.background-menu("/media/images/menu/contact.png");
    }
    .menuHomeScreen{
      @include mixin.background-menu("/media/images/menu/homeScreen.png");
    }
    .menuDisconnect{
      @include mixin.background-menu("/media/images/menu/disconnect.png");
    }
    .menuCreateAccount{
      @include mixin.background-menu("/media/images/menu/monCompte.png");
      margin-bottom:20px;
    }
    .menuQrCode{
      @include mixin.background-menu("/media/images/menu/icon-code-barre.svg");
      background-size: 30px 27px;
    }
    .menuListGiftCard{
      @include mixin.background-menu("/media/images/menu/ico_cartesCadeau.svg");
    }
  }
