
@use '../config/colors' as colors;

.createUserScene{
    margin:auto;
    display:flex;
    flex-direction: column;
    margin-top: -100px !important; /// Pour contrer le décalage sur toutes les autres scènes
    .updateForm{
        width:100%;
        margin:16px auto 0 auto;
        .civilite{
            span{
                font-family: 'Ubuntu' !important;
                font-weight: 400;
                font-size: 1.3em !important;
                color: colors.$textGrisFonce;
                svg{
                    color: colors.$vertMoyen;
                }
            }
        }
        .dateNaissance{
            margin:10px 0 20px 0;
            width: 100%;
            label{
                font-weight: 400;
                color: colors.$vertClair;
                font-size: 1.3em;
                text-transform: uppercase;
                text-align: right;
            }
            input{
                font-family: 'Ubuntu';
                height:26px;
                font-weight: 400;
                font-size: 1.3em;
                color: colors.$textGrisFonce;
                border:0;
                background-color:transparent;
            }
            .birthdateValidated{
                margin-top:-10px;
                width: 100%;
                input{
                    font-family: 'Ubuntu';
                    height:26px;
                    font-weight: 400;
                    font-size: 1.2em;
                    color: colors.$textGrisFonce;
                    border:0;
                    background-color:transparent;
                }
            }
        }
        .cguBlock{
            text-align: center;
            margin-top: 6px;
            a{
                color: colors.$vertSombre;
            }
        }
    }
}

@media screen and (max-width:699px) {
    .createUserScene{
        .updateForm{
            .dateNaissance{
                label{
                    font-size: 1.2em;
                }
                input{
                    font-size: 1.2em;
                }
                .birthdateValidated,
                input{
                    font-size: 1.2em;
                }
            }
        }
    }
  }

  @media screen and (max-width:360px) {
    .createUserScene{
        .updateForm{
            .dateNaissance{
                label{
                    font-size:1.1em;
                }
                input{
                    font-size: 1.1em;
                }
                .birthdateValidated{
                    input{
                        font-size:1em;
                        }
                }
            }
        }
    }
  }
