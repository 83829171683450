@use '../config/_colors' as colors;

  /// Font size
  .small-11{
    font-size:11px;
  }
  .small-12{
    font-size:11px;
  }
  .medium-16{
    font-size: 16px;
  }
  .medium-18{
    font-size: 18px;
  }
  .medium-22{
    font-size: 22px;
  }
  .large-28{
    font-size: 28px;
  }

  // Font Weight
  .light{
    font-weight: 300;
  }
  .regular{
    font-weight: 400;
  }
  .medium{
    font-weight: 500;
  }
  .semi-bold{
    font-weight: 600;
  }
  .bold{
    font-weight: 700;
  }

// Font colors
  .text-vert-sombre{
    color: colors.$vertSombre;
  }
  .text-vert-moyen{
    color: colors.$vertMoyen;
  }
  .text-orange{
    color: colors.$titreOrange;
  }
  .text-gris-fonce{
    color: colors.$textGrisFonce;
  }
  .text-gris-clair{
    color: colors.$textGrisClair;
  }