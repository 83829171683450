@use '../config/colors' as colors;
@use '../config/mixin' as mixin;

.retailerSwitch{
    display: flex;
    flex-direction: row;
    align-self: center;
    height: 48px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
    margin-top: -8px;
    .switchButton{
        width: 168px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    }
    .offline{
        border-radius: 22px 0 0 22px; 
        background-color: white;
        color: colors.$textGrisClair;
    }
    .offlineActive{
        color: white;
        background-color: colors.$vertClair;
    }
    .online{
        color: colors.$textGrisClair;
        background-color: white;
        border-radius:  0 22px 22px 0; 
    }
    .onlineActive{
        background-color: colors.$bleuClair;   
        color: white;
    }
    .tick{
        @include mixin.background-sizing-svg("/media/images/map/icon_tic.svg");
        width: 16px;
        height: 12px;
        align-self: center;
    }
    .check{
        @include mixin.background-sizing-svg("/media/images/map/icon_checkbox.svg");
        width: 14px;
        height: 14px;
        align-self: center;
    }
    .textButton{
        align-self: center;
        margin-left: 8px;
    }
}

@media screen and (max-width:380px) {
    .retailerSwitch{
        font-size: 12px;
        .switchButton{
            width: 148px;
        }
    }
}

@media screen and (max-width:320px) {
    .retailerSwitch{
        font-size: 11px;
        .switchButton{
            width: 128px;
        }
        .textButton{
            margin-left: 4px;
        }
    }
}