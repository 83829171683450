@use '../config/colors' as colors;
@use '../config/mixin' as mixin;

.couponDetailsItem{
    width: 376px;
    .mainLineBlock{
        display:flex;
        flex-direction: row;
        background-color: white;
        width: 100%;
        .productImage{
            flex-grow: 2;
            width: 60%;
            text-align: center;
            align-self: center;
            .checkProductBtn{
                width: 66%;
                height: 20px;
                margin: 4px auto 0 auto;
            }
        }
        .priceBlock{
            width: 40%;
            @include mixin.background-sizing('/media/images/cart/coupon/bg-decoupe-detail.png');
            width: 166px;
            height: 164px;
        }
    }
    .descriptionBlock{
        font-family: 'Source Sans Pro', sans-serif;
        width: 100%;
        background-color: white;
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        background-image: linear-gradient(to right top,rgba(0,0,0,.1),transparent 50%);
        .couponName{
            margin: 0 14px 0 14px;
            font-size: 15px;
            font-weight: 600;
            color: colors.$textGrisFonce;
            width: 90%;
        }
        .couponDescription{
        margin: 6px 14px 0 14px;
        font-size: 13px;
        line-height: 13px;
        font-weight: 300;
        color: colors.$textGrisClair;
        width: 90%;
        }
        .lineBreak{
            margin: 16px 14px 16px 14px;
            width: 90%;
            border: solid 1px colors.$textGrisClair;
        }
        .modalite{
            color: colors.$fondOrangeMoyen;
            font-size: 15px;
            font-weight: 700;
            text-transform: uppercase;
            margin: 0 14px 0 14px;
        }
        .useCase{
            margin: 6px 14px 20px 14px;
            font-size: 13px;
            line-height: 13px;
            font-weight: 300;
            color: colors.$textGrisClair;
            width: 90%;
        }
        .whereToFindBtn{
            width: 66%;
            height: 20px;
            margin: 4px auto 20px auto;
        }
    }
}

@media screen and (max-width:600px){
    .couponDetailsItem{
        width: 100vw;
    }
  }
