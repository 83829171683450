@use '../config/colors' as colors;
@use '../config/mixin' as mixin;

.cartScene{
    margin:auto;
    display:flex;
    flex-direction: column;
    @include mixin.backgroundScene();
    .economyBlock{
        width: 100vw;
        background-color: colors.$vertTresSombre;
        display: flex;
        flex-direction: column;
        .economyTitle{
            color: white;
            font-size: 20px;
            margin: 23px auto 0 auto;
            font-weight: 700;
        }
        .economyValue{
            font-weight: 900;
            color: white;
            width: 96%;
            line-height: 1em;
            margin: 10px auto 12px auto;
            text-align: center;
            font-size: 50px;
            .big{
                font-size: 1.3em;
            }
            .small{
                font-size: 1em;
            }
            .euroSymbol{
                font-size: 1.3em;
            }
        }
        .showQrBlock{
            margin-bottom: 18px;
            .qrCodeButton{
                background-color: colors.$titreOrange;
                width: 270px;
                height: 25px;
                color: white;
                margin:auto;
                font-weight:700;
                font-size:1.3em;
                text-transform: uppercase;
                display:flex;
                justify-content: center;
                align-items: center;
                .iconQrCode{
                    width: 24px;
                    height: 24px;
                    margin-left: 13px;
                }
            }
        }
    }
    
    .noCoupons{
        margin: auto;
        width:100%;
        color: colors.$titreOrange;
        margin:0 auto 30px auto;
        font-weight:700;
        font-size:1em;
        text-align: center;
    }
    .couponList{
        margin: 0 auto 10px auto;
        display: flex;
        flex-direction: row;
        width: 100vw;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
    }
}


@media screen and (max-width:420px) {
    .cartScene{
        .noCoupons{
            width:86%;
            color: colors.$titreOrange;
        }
    }
}

@media screen and (max-width:320px) {
    .cartScene{
        .localisationBloc{
            .localisationButton{
                width: 240px;
            }
        }
    }
}