@use '../config/colors' as colors;
@use '../config/mixin' as mixin;

.externalCouponDetailsScene{
    background-image: linear-gradient(38deg, #98cde2 9%, #c1e4f2 75%);
    min-height: calc(100vh - 70px);
    width: 100vw;
    margin:auto;
    display:flex;
    flex-direction: column;
    .couponList{
        .couponContainer{
            margin:30px auto 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: calc(100vh - 100px);
            .topBloc{
                display:flex;
                flex-direction: row;
                justify-content: center;
                z-index: 2;
                .topProductBloc{
                    width: 520px;
                    .externalTitle{
                        color: colors.$vertSombre;
                        font-size: 1.9em;
                        font-weight: 600;
                        h1{
                            text-align: left;
                        }
                    }
                    .couponHeader{
                        @include mixin.background-sizing-svg("/media/images/cart/bg_bri.svg");
                        width: 510px;
                        height: 218px;
                        align-self: center;
                        display: flex;
                        flex-direction:row;
                        .productImage{
                            flex-grow: 2;
                            width: 45%;
                            text-align: center;
                            align-self: center;
                        }
                        .productPrice{
                            width: 55%;
                            .couponValue{
                                font-weight: 900;
                                color: colors.$vertSombre;
                                width: 96%;
                                line-height: 1em;
                                margin-top: 28%;
                                text-align: center;
                                font-size: 26px;
                                .big{
                                    font-size: 3.5em;
                                }
                                .small{
                                    font-size: 2em;
                                }
                                .euroSymbol{
                                    font-weight: 900;
                                    font-size: 2.8em;
                                }
                                .rembourse{
                                    font-weight: 900;
                                    font-size: 0.9em;
                                    text-transform: uppercase;
                                    text-align: center;
                                }
                            }
                        }
                    }
                    .ajouterBtn{
                        width: 65%;
                        margin: -31px auto 40px auto;
                        height: 50px;
                        font-size: 18px;
                        .text_btn{
                            text-align: center;
                            width: 80%;
                        }
                    }
                }
                .noAccount{
                    width: 360px;
                    margin: 0 0 -30px 80px;
                    border-radius: 25px;
                    background-color: colors.$vertSombre;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;
                    font-size: 18px;
                    line-height: 22px;
                    .noTitle{
                        margin: 20px auto 12px auto;
                        color: colors.$vertClair;
                    }
                    .noText{
                        width: 80%;
                        color: white;
                        text-align:center;
                    }
                    .textEngagement{
                        color: white;
                        text-decoration: underline;
                    }
                    .inscrit_btn{
                        width: 54%;
                        margin: 26px auto 30px auto;
                        height: 22px;
                        background-color: colors.$vertClair;
                    }
                }
            }
            
            .bottomBloc{
                background-color: white;
                padding-bottom: 30px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                flex-grow: 2;
                .couponDetailsBloc{
                    width:560px;
                    .productLine{
                        margin-top: 26px;
                        display:flex;
                        flex-direction: row;
                        .productImage{
                            flex-grow: 2;
                            width: 45%;
                            text-align: center;
                            align-self: center;
                        }
                        .productDetails{
                            width: 55%;
                            text-align: left;
                            align-self: center;
                            .productDescription{
                                margin-top: 8px;
                                font-size: 13px;
                                line-height: 13px;
                                font-weight: 300;
                                color: colors.$textGrisClair;
                            }
                        }
                    }
                    .lineBreak{
                        margin: 16px 14px 16px 14px;
                        width: 100%;
                        border: solid 1px colors.$textGrisClair;
                    }
                    .modalite{
                        color: colors.$fondOrangeMoyen;
                        font-size: 15px;
                        font-weight: 700;
                        text-transform: uppercase;
                        margin: 12px 14px 12px 14px;
                    }
                    .useCase{
                        margin: 6px 14px 20px 14px;
                        font-size: 13px;
                        line-height: 13px;
                        font-weight: 300;
                        color: colors.$textGrisClair;
                        width: 90%;
                    }
                }
                .fakeBloc{
                    display: block;
                    width: 320px;
                    margin-left: 80px;
                }
            }
        }
    }
}


@media screen and (max-width:980px) {
    .externalCouponDetailsScene{
        .couponList{
            .couponContainer{
                .topBloc{
                    flex-direction: column;
                    .topProductBloc{
                        width: 100%;
                        margin: auto;
                        .externalTitle{
                            font-size: 1.7em;
                            h1{
                                text-align: center;
                            }
                        }
                        .couponHeader{
                            margin: auto;
                            .productPrice{
                                .couponValue{
                                    font-size: 22px;
                                }
                            }
                        }
                        .ajouterBtn{
                            width: 80%;
                            margin: 20px auto 30px auto;
                            height: 50px;
                            .text_btn{
                                text-align: center;
                                width: 80%;
                            }
                        }
                    }
                    .noAccount{
                        background-color: colors.$vertSombre;
                        width: 90%;
                        margin: auto;
                        font-size: 16px;
                        line-height: 19px;
                    }
                }
                .bottomBloc{
                    .couponDetailsBloc{
                        .lineBreak{
                            width: 90%;
                        }
                    }
                    .fakeBloc{
                        display: none;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:500px) {
    .externalCouponDetailsScene{
        .couponList{
            .couponContainer{
                .topBloc{
                    .topProductBloc{
                        .couponHeader{
                            width: 350px;
                            height: 150px;
                            .productPrice{
                                .couponValue{
                                    font-size: 20px;
                                }
                            }
                        }
                    }
                    .noAccount{
                        width: 100%;
                        border-radius: 0;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:320px) {
    .externalCouponDetailsScene{
        .couponList{
            .couponContainer{
                .topBloc{
                    .topProductBloc{
                        .couponHeader{
                            width: 300px;
                            height: 129px;
                            .productPrice{
                                .couponValue{
                                    font-size: 18px;
                                }
                            }
                        }
                        .ajouterBtn{
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}
