.contactScene{
  margin:auto;
  display:flex;
  flex-direction: column;
  margin-top: -100px !important; /// Pour contrer le décalage sur toutes les autres scènes
  min-height: 103vh;

  .mainContent{
    width:100%;
    margin:auto;
    max-width: 1000px;
    padding-top: 12px;

    .formulaireContact{
      width:100%;
      margin-top: 120px;
      .entete{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        .cliente{
          width:30%;
        }
        .baseline{
          width:63%;
          box-sizing: content-box;
          border: 0;
          border-radius: 50px 50px 50px 0;
          text-overflow: ellipsis;
          background-color:rgba(247, 166, 0, 1);
          padding:50px 0 86px 0;
          max-width: 400px;
          .textContainer{
            font-family: 'Ubuntu';
            font-weight: 400;
            font-size: 1.7em;
            color: white;
            padding-left: 90px;
            padding-right:20px;
            .textBold{
              font-weight:700;
            }
          }
        }
      }
      .interet{
        font-weight:700;
        font-size:2.25em;
        color:rgba(13, 126, 109, 1);
        width:100%;
        margin:auto;
        text-align:center;
        margin-bottom:30px;
        margin-top:50px;
        text-transform: uppercase;
      }
     .captchaContainer{
       margin-top: 12px;
     }
    }
  }
}
  

@media screen and (max-width:500px) {
  .contactScene{
    width:99%;
    .mainContent{
      .formulaireContact{
        width:100%;
        .entete{  
          .cliente{
            display: none;
          }
          .baseline{
            width: 100%;
          }
        }
        .interet{
          font-size:1.9em;
        }
        .formContainer{
          .captchaContainer{
            margin-left:7.5vw;
          }
        }
      }
    }
    
  }
}

@media screen and (max-width:360px) {
  .formulaireContact{
    .interet{
      font-size:1.5em;
    }
    .formContainer{
      .captchaContainer{
        margin-left:5vw;
      }
    }
  }
}
