@use '../config/colors' as colors;
@use '../config/mixin' as mixin;

.stampListScene{
    margin:auto;
    @include mixin.backgroundScene();
    .stampListContainer{
        display:flex;
        flex-direction: column;
        justify-content: flex-start;
        min-height: calc(100vh - 70px);
        .clubList{
            display:flex;
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;
            .campaignClubItem{
                background-color: white;
                border-radius: 20px;
                padding-top: 12px;
                border-top: solid 1px colors.$textGrisClair;
                margin:0 20px 8px 20px;
                width: 500px;
                .fidelityCampaignDetails{
                    margin: 12px 0 10px 25px;
                    display:flex;
                    flex-direction: row;
                    .fidelityCampaignProduct{
                        .productImage{
                            height: 80px;
                        }
                    }
                    .fidelityCampaignName{
                        font-size: 18px;
                        font-weight: 600;
                        color: colors.$vertMoyenSombre;
                        align-self: center;
                        margin-left: 12px;
                    }
                }
                .clubItem{
                    display: flex;
                    border-top: solid 1px colors.$textGrisClair;
                    height: 100px;
                    
                    //@include background-image(linear-gradient(left bottom, rgba(0,0,0,0.10) 0%,rgba(0,0,0,0) 30%));
                    .clubScore{
                        width: 107px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        .scoreCircle{
                            width: 66px;
                            height: 66px;
                            background-color: colors.$vertMoyenClair;
                            border-radius: 50%;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            text-align: center;
                            color: white;
                            font-feature-settings: 'lnum' 1;
                            font-weight: 700;
                            .scoreDetails{
                                font-size: 30px;
                                margin-right: -5px;
                            }
                            .scoreTotal{
                                font-size: 14px;
                            }
                        }
                    }
                    .clubDetails{
                        flex-grow: 2;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        .retailerName{
                            font-size: 17px;
                            font-weight: 600;
                            color: colors.$vertMoyen;
                        }
                    }
                    .retailerArrow{
                        width: 50px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                }
            }
            
        }
    }
}

@media screen and (max-width:1024px) {
    .stampListScene{
        background-size: cover;
        background-position: 50% 50%;
        .stampListContainer{
            .clubList{
                flex-direction: column;
                .campaignClubItem{
                    margin:0 auto 8px auto;
                    width: 100%
                }
            }
        }
    }
}

@media screen and (max-width:420px) {
    .stampListScene{
        background-image: none;
        background-color: colors.$fondGrisClair;
    }
}