@use '../config/colors' as colors;

.acceptCguScene{
    justify-content: flex-start;
    .acceptCguContainer{
        .greyBlock{
            display:flex;
            flex-direction: column;
            justify-content: flex-start;
            background-color: colors.$fondGrisClair;
            border-radius: 0 0 20px 20px;
            min-height: calc(100vh - 70px);
            .sceneTitle{
                font-size: 24px;
                font-weight: 600;
                color: colors.$titreOrange;
                margin: 40px auto 0 auto;
                width: 80%;
                text-align: center;
            }
            .clubLogo{
                margin: 12px auto 10px auto;
            }
            .acceptText{
                width: 70%;
                font-size: 14px;
                font-weight: 400;
                margin: 30px auto;
                text-align: center;
                max-width: 800px;
                .acceptCguButton{
                    margin: 20px auto;
                }
                .cguBlock{
                    font-size: 12px;
                    margin: 12px auto 20px auto;
                }
                a{
                    color: colors.$vertMoyenClair;
                }
            }
        }
    }
}