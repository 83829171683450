@use '../config/colors' as colors;
@use '../config/mixin' as mixin;

.giftCardItem{
    width: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
    .imageBlock{
        img {
            width: 160px;
            border-radius: 15px;
            filter: drop-shadow(5px 5px 5px #666666);
        }
        .giftCardImage{
            width: 160px;
        }
    }
    .contentBlock{
        margin: 0 10px;
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 6px 0 16px 0;
        .giftCardAmount{
            font-weight: 600;
            font-size: 13px;
            color: colors.$charcoalGrey;
            text-align: center;
            .big{
                font-size: 2.5em;
            }
            .small{
                font-size: 1.4em;
            }
            .euroSymbol{
                font-size: 1.8em;
            }
        }
        .giftCardEndDate{
            font-size: 15px;
            line-height: 13px;
            font-weight: 400;
            color: colors.$textGrisClair;
            margin-top: 6px;
        }
    }
}

@media screen and (max-width:320px){
    .giftCardItem{
        width: 150px;
        .imageBlock{
            img {
                width: 150px;
            }
            .giftCardImage{
                width: 150px;
            }
        }
        .contentBlock{
            .giftCardAmount{
                font-size: 12px;
            }
            .giftCardEndDate{
                font-size: 13px;
            }
        }
    }
  }