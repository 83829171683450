@use '../config/colors' as colors;

@mixin background-sizing($imgpath, $position:0 0, $repeat: no-repeat) {
    @if file_exists($imgpath) {
        background:url($imgpath) $position $repeat;
        //width: image-width($imgpath);
        //height: image-height($imgpath);
        background-size: cover;
    }
}

@mixin background-sizing-coupon($imgpath, $position:0 0, $repeat: repeat-y) {
    @if file_exists($imgpath) {
        background:url($imgpath) $position $repeat;
        width: 100vw;
        background-size: contain;
    }
}

@mixin background-sizing-dedicated($imgpath, $position:0 0, $repeat: repeat-x) {
    @if file_exists($imgpath) {
        background:url($imgpath) $position $repeat;
        width: 100%;
        background-size: contain;
    }
}

@mixin background-sizing-auto($imgpath, $position:0 0, $repeat: no-repeat) {
    @if file_exists($imgpath) {
        background:url($imgpath) $position $repeat;
        //width: image-width($imgpath);
        //height: image-height($imgpath);
        background-size: auto;
    }
}

@mixin background-menu($imgpath, $position:0 0, $repeat: no-repeat) {
    @if file_exists($imgpath) {
        background:url($imgpath) $position $repeat;
        font-weight: 700;
        text-transform: uppercase;
        text-decoration: none;
        cursor: pointer;
        font-size: 0.8em;
        height: 20px;
        padding: 10px 0 10px 40px;
    }
}

@mixin backgroundScene(){
    //background: url('/media/images/common/desktop-background.jpg') 0 no-repeat;
    //background-size: 100vw;
    //background-color: colors.$fondOrange;
    background-color: colors.$fondGrisClair;
    min-height: calc(100vh - 70px);
}

@mixin background-sizing-svg($imgpath, $position:0 0, $repeat: no-repeat) {
    @if file_exists($imgpath) {
        background:url($imgpath) $position $repeat;
        background-size: cover;
    }
}

@mixin background-no-sizing-svg($imgpath, $position:0 0, $repeat: repeat) {
    @if file_exists($imgpath) {
        background:url($imgpath) $position $repeat;
        background-size: auto;
    }
}
