@use '../config/colors' as colors;
@use '../config/mixin' as mixin;

.giftCardCampaignDetailsScene{
    justify-content: flex-start;
    .giftCardCampaignDetailsContainer{
        min-height: calc(100vh - 70px);
        background-color: colors.$fondGrisClair;
        border-radius: 20px 20px 0 0;
        margin-top: 100px;
        box-shadow: 0 3px 23px 0 rgba(0, 0, 0, 0.3);
        .cardInfo{
            .giftCardItem{
                width: 100%;
                display:flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: flex-start;
                .imageBlock{
                    margin: -90px 0 0 27px;
                    img{
                        border-radius: 25px;
                    }
                    .giftCardImage{
                        width: 260px;
                    }
                }
                .contentBlock{
                    min-width: 310px;
                    .giftCardName{
                        font-size: 28px;
                        font-weight: 500;
                        color: colors.$charcoalGrey;
                        width: 90%;
                        margin: 12px 0 0 20px;
                    }
                    .subLineBlock{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        width: 98%;
                        .amountBlock{
                            width: 45%;
                            margin-left: 20px;
                            .giftCardAmount{
                                margin-top: 10px;
                                text-align: left;
                                display:flex;
                                flex-direction: row;
                                justify-content: flex-start;
                                .borderLine{
                                    height: 80px;
                                    width: 10px;
                                    background-color: colors.$vertSombre;
                                    border-radius: 99px;
                                    margin: 7px 16px 0 0;
                                }
                                .itemContent{
                                    .amountLabel{
                                        font-size: 14px;
                                        font-weight: 400;
                                        color: colors.$textGris;
                                    }
                                    .amountValue{
                                        font-size: 18px;
                                        color: colors.$vertSombre;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .productsList{
            border-top: solid 1px grey;
            .productTitle{
                margin: 20px auto 0 auto;
                text-align: left;
                color: colors.$charcoalGrey;
                font-size: 19px;
                font-weight: 500;
                width: 94%;
            }
            .productItems{
                background-color: white;
                border-radius: 25px;
                margin: 14px auto;
                width: 94%;
                padding: 12px 0;
                .productLine{
                    width: 90%;
                    margin: 12px auto;
                    .productName{
                        font-weight: 600;
                    }
                    .productEan{
                        font-style: italic;
                        font-size: 12px;
                    }
                }
            }
            .vérifierBtn{
                max-width: 400px;
                width: 80%;
                margin: 14px auto;
            }
        }
        .retailerListContainer{
            background-color: white;
            border-top: solid 1px grey;
            z-index: 1;
            .retailerTitle{
                margin: 20px auto 20px auto;
                text-align: left;
                color: colors.$charcoalGrey;
                font-size: 19px;
                font-weight: 500;
                width: 94%;
            }
            .retailersMap{
                width: 94%;
                height: 600px;
                margin: 20px auto;
                .retailerAddress{
                    font-size: 11px;
                }
                .retailerUrl{
                    display: block;

                }
            }
            .switchBlock{
                z-index: 6;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                margin: -20px auto 0 auto;
                width: 600px;
                position: relative;
            }
        }
    }
}

@media screen and (max-width: 600px){
    .giftCardCampaignDetailsScene{
        .giftCardCampaignDetailsContainer{
            .retailerListContainer{
                .switchBlock{
                    width: 100%;
                }
            }
        }
    }
}