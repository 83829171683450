.logoHeader{
    display: flex;
    justify-content: center;
    .paypsLogo{
        width:185px;
        height: 272px;
        margin-top: 26px;
    }
}

@media screen and (max-width:699px) {
    .logoHeader{
        .paypsLogo{
            width:185px;
            height: 272px;
        }
    }
}

@media screen and (max-width:400px) {
    .logoHeader{
        .paypsLogo{
            width:140px;
            height: 206px;
        }
    }
}