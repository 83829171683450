@use '../config/colors' as colors;

.rgpdFilesScene{
    margin:auto;
    .version{
        font-size: 11px;
        font-weight: 400;
        color: colors.$textGrisClair;
        width: 80%;
        margin: 0 auto 12px auto;
    }
    .texte{
        font-size: 13px;
        font-weight: 500;
        color: colors.$textGrisFonce;
        width: 90%;
        margin: 0 auto 30px auto;
        a,
        .clickLink{
            color: colors.$vertSombre;
            cursor: pointer;
            text-decoration: underline;
        }
        h2{
            font-size: 14px;
            font-size: 600;
            color: colors.$vertSombre;
            margin: 22px 0;
        }

        h3{
            font-size: 13px;
            font-size: 500;
            color: colors.$vertSombre;
            margin: 22px 0;
        }
        .listCookies{
            font-weight: 600;
            display: block;
            margin-bottom: 10px;
        }
        ul{
            list-style: disc;
        }
        .textUnderBold{
            font-weight: 600;
            text-decoration: underline;;
        }
    }
}
