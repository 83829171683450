@use '../config/colors' as colors;

.retailersMap{
    height: 300px;
    z-index: 6;
    position: relative;
    .gMapsContainer{
        height:100%;
    }
    .retailerInfoWindow{
        display: flex;
        flex-direction: row;
        align-items: center;
        min-width: 270px;
        .retailerLogo{
            background-color: white;
            margin: 6px 8px 0 6px;
        }
        .retailerDetails{
            font-size: 16px; // Need to redefine in PX because of GMaps predefined styles
            margin: 4px 0 4px 0;
            .retailerName{
                font-size:1em;
                font-weight:700;
                color: colors.$textGrisFonce;
            }
            .retailerDiscount{
                display: flex;
                flex-direction: row;
                align-items: center;
                .discountText{
                    text-transform: uppercase;
                    font-size:0.6em;
                    font-weight:400;
                    color: colors.$textGrisFonce;
                }
                .totalDiscount{
                    font-weight: 700;
                    color: colors.$textGrisFonce;
                    font-size: 0.8em;
                }
            }
        }
    }
}
.leaflet-container{
    height: 300px;
    width: 100%;
    overflow: hidden;
}
.leaflet-safari 
.leaflet-tile-container{
    width: 1600px;
    height: 1600px;
    -webkit-transform-origin: 0 0;
}

.leaflet-pane, 
.leaflet-tile, 
.leaflet-marker-icon, 
.leaflet-marker-shadow, 
.leaflet-tile-container, 
.leaflet-pane > svg, 
.leaflet-pane > canvas, 
.leaflet-zoom-box, 
.leaflet-image-layer, 
.leaflet-layer{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 400;
}

.closeButton{
    cursor: pointer;
    margin: -8px 0 -8px 94%;
}


@media screen and (max-width:360px) {
    .retailersMap{
        .retailerInfoWindow{
            .retailerDetails{
                .retailerName{
                    font-size:0.9em;
                }
            }
        }
    }
}

@media screen and (max-width:320px) {
    .retailersMap{
        .retailerInfoWindow{
            .retailerDetails{
                .retailerName{
                    font-size:0.9em;
                }
            }
        }
    }
}