@use '../config/colors' as colors;
@use '../config/mixin' as mixin;

.retailersScene{
    margin:auto;
    display:flex;
    flex-direction: column;
    background-color: white;
    min-height: calc(100vh - 70px);
    .headerList{
        background-color: colors.$fondGrisClair;
        .titleSmallMargin{
            margin: 24px auto 0px auto;
        }
        .geolocButton{
            margin-top:20px;
        }
    }
    .showPopup{
        z-index: 10;
    }
    .hidePopup{
        z-index: 1;
    }
    .switchBlock{
        z-index: 6;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin: -20px auto 0 auto;
        width: 600px;
    }
    .noResults{
        width: 80%;
        max-width: 600px;
        margin: 20px auto 0 auto;
        color: colors.$textGris;
        font-size : 16px;
        font-weight: 700;
    }
    .listContainer{
        z-index: 2;
        background-color: white;
        margin-top: -86px;
        width: 100%;
    }
    
}

@media screen and (max-width: 600px){
    .retailersScene{
        .switchBlock{
            width: 100%;
        }
    }
}

