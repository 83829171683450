@use '../config/colors' as colors;
@use '../config/mixin' as mixin;

.couponDetailsScene{
    width: 100vw;
    margin:auto;
    display:flex;
    flex-direction: column;
    @include mixin.backgroundScene();
    background-position: inherit;
    .habillageClient{
        //height: 200px;
        margin: auto;
        //flex-grow: 2;
        .imageHabillage{
            width: 376px;
            max-width:650px;
            object-fit: cover;
            overflow: hidden;
            display: block;
        }
    }
    .habillageDefault{
        font-family: 'Source Sans Pro', sans-serif;
        margin-bottom: 40px;
        .titleBloc{
            width:100%;
            text-align: center;
            .titleCoupon{
                font-weight:700;
                font-size: 2.5em;
                color: colors.$vertSombre;
                width:90%;
                padding-top: 60px;
                line-height: 0.9em;
                margin: auto;
            }
            .descriptionCoupon{
                font-weight:600;
                font-size: 1em;
                color: colors.$textGrisMoyen;
                padding-top: 8px;
                width:90%;
                margin: auto;
            }
        }
    }
    .couponList{
        margin: 0 auto 0 auto;
        flex-grow: 2;
    }
}

@media screen and (max-width:1024px) {
    .defaultBackground{
        background-size: cover;
        background-position: 50% 50%;
    }
}

@media screen and (max-width:420px) {
    .couponDetailsScene{
        .habillageClient{
            .imageHabillage{
                width: 100vw;
            }
        }
    }
    .defaultBackground{
        background-image: none;
    }
}