@use '../config/colors' as colors;
@use '../config/mixin' as mixin;

.homeScene{
    margin:auto;
    display:flex;
    flex-direction: column;
    margin-top: -70px !important; /// Pour contrer le décalage sur toutes les autres scènes
    min-height: 100vh;
    background-color: white;
    @include mixin.background-sizing-svg("/media/images/home/background.svg");
    .homeContainer{
        @include mixin.background-sizing-svg("/media/images/home/traits.svg");
        background-position: center;
        .greyContainer{
            width: 1016px;
            border-radius: 28px;
            background-color: #f5f5f5;
            margin: 20px auto 50px auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .claimPayps{
                font-size: 25px;
                line-height: 28px;
                font-weight: 800;
                margin: auto;
                text-align: center;
                .claimTop{
                    color: colors.$vertSombre;
                }
                .claimBottom{
                    color: colors.$vertMoyenClair;
                }
            }
            .buttonLine{
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                margin-bottom: 70px;
                .registerBloc{
                    width: 514px;
                    height: 378px;
                    border-radius: 34px;
                    background-image: linear-gradient(179deg, #19806f 56%, #00ccac 99%);
                    font-weight:700;
                    font-size: 22px;
                    text-transform: uppercase;
                    color: white;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .textProfiter{
                        text-transform: none;
                        font-size: 18px;
                        font-weight: 600;
                        margin: 40px auto 27px auto;
                    }
                    .or{
                        margin:15px auto 15px auto;
                        font-size: 0.6em;
                        background-color: colors.$vertClair;
                        width: 38px;
                        height: 38px;
                        line-height: 38px;
                        border-radius: 50%;
                    }
                    .identificationBtn{
                        background-color: colors.$fondOrangeJaune;
                        width: 70%;
                        margin: auto;
                        box-shadow: 0 -7px 10px 0 rgba(255, 255, 255, 0.26);
                    }
                    .inscriptionBtn{
                        background-color: colors.$fondOrangeJaune;
                        width: 70%;
                        margin: 0 auto 50px auto;
                        box-shadow: 0 -7px 10px 0 rgba(255, 255, 255, 0.26);
                    }
                    
                    a{
                        text-decoration: none;
                    }
                }
                .secondLine{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 396px;
                    .assurance{
                        display: block;
                        width: 100%;
                        margin: 0 auto 30px auto;
                        text-align: center;
                        font-size: 18px;
                        line-height: 22px;
                        font-weight: 500;
                        span{
                            display: block;
                        }
                        .assuranceTitle{
                            font-size: 26px;
                            line-height: 28px;
                            color: colors.$textGris;
                        }
                        .assuranceDescription{
                            color: colors.$vertMoyen;
                            font-weight: 600;
                        }
                        .assuranceSub{
                            color: colors.$textGris;
                        }
                        .textGratuit{
                            margin-top: 8px;
                            color: colors.$vertMoyenClair;
                            text-decoration: underline;
                        }
                    }
                    .whiteBlocs{
                        display:flex;
                        flex-direction: row;
                        justify-content: space-between;
                        width: 400px;
                        a{
                            text-decoration: none;
                        }
                        .whiteBloc{
                            width: 185px;
                            height: 224px;
                            background-color: white;
                            border-radius: 34px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
                        }
                        .backgroundIcon{
                            width: 87px;
                            height:87px;
                            background-color: #f5f5f5;
                            border-radius: 50%;
                            margin-top: 21px;
                            .discoverIcon{
                                @include mixin.background-sizing-svg("/media/images/home/ico_lieu.svg");
                                background-size: contain;
                                width: 50%;
                                height: 50%;
                                margin: 18px auto 0 auto;
                            }
                            .faqIcon{
                                @include mixin.background-sizing-svg("/media/images/home/ico_interr.svg");
                                background-size: contain;
                                width: 50%;
                                height: 50%;
                                margin: 18px auto 0 auto;
                            }
                        }
                        .detailsText{
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 17px;
                            color: colors.$textGris;
                            width: 80%;
                            text-align: center;
                            margin-top:20px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width:1040px) {
    .homeScene{
        .homeContainer{
            .greyContainer{
                width: 100%;
                margin: 20px auto 0 auto;
                border-radius: 28px 28px 0 0;
                .claimPayps{
                    font-size: 22px;
                    line-height: 24px;
                    width: 88%;
                }
                .buttonLine{
                    flex-direction: column;
                    align-items: center;
                    margin: auto;
                    width: 90%;
                    .registerBloc{
                        background-image: none;
                        background-color: white;
                        height: 328px;
                        .textProfiter{
                            color: colors.$textGris;
                        }
                        .or{
                            background-color: #f5f5f5;
                            color: colors.$textGris;
                        }
                        .identificationBtn{
                            box-shadow: 3px 6px 9px 0 rgba(0, 0, 0, 0.21);
                        }
                        .inscriptionBtn{
                            box-shadow: 3px 6px 9px 0 rgba(0, 0, 0, 0.21);
                        }
                    }
                    .secondLine{
                        width: 514px;
                        .assurance{
                            margin: 20px auto 30px auto;
                        }
                        .whiteBlocs{
                            width: 100%;
                            justify-content: space-evenly;
                            margin-bottom: 30px;
                            .whiteBloc{
                                height: 210px;
                            }
                            .backgroundIcon{
                                width: 100px;
                                height:100px;
                            }
                            .backgroundIcon{
                                margin-top: 26px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width:500px) {
    .homeScene{
        .homeContainer{
            .greyContainer{
                .buttonLine{
                    .registerBloc{
                        width: 90%;
                    }
                    .secondLine{
                        width: 100%;
                        .assurance{
                            width: 90%;
                        }
                        .whiteBlocs{
                            width: 90%;
                            justify-content: space-between;
                            .whiteBloc{
                                width: 130px;
                            }
                            .backgroundIcon{
                                width: 80px;
                                height:80px;
                            }
                            .detailsText{
                                font-size: 13px;
                                line-height: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width:320px) {
    .homeScene{
        .homeContainer{
            .greyContainer{
                .buttonLine{
                    .secondLine{
                        .whiteBlocs{
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}