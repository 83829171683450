@use '../config/colors' as colors;
@use '../config/mixin' as mixin;

.listCouponsScene{
    margin:auto;
    display:flex;
    flex-direction: column;
    @include mixin.backgroundScene();
    .titleRubrique{
        font-size: 2em;
    }
    .noCoupons{
        margin: auto;
        width:86%;
        color: colors.$titreOrange;
        margin:0 auto 20px auto;
        font-weight:700;
        font-size:1em;
    }
    .retailerData{
        margin : 6px auto 26px auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        .retailerLogo{
            margin-right: 2px;
        }
        .retailerName{
            font-weight: 700;
            color: colors.$textGrisMoyen;
            font-size: 1.4em;
            text-transform: uppercase;
        }
    }
    .couponList{
        margin: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-content: center;
        flex-grow: 2;
        margin-bottom: 17px;
    }
    .moreCoupon{
        @include mixin.background-sizing('/media/images/cart/bonDeReduction-new.png');
        width: 376px;
        opacity: 0.7;
        .textBientot{
            margin: 35px;
            font-size: 1.4em;
            font-weight: 700;
            color: colors.$vertClair;
            text-align: center;
        }
    }
}

@media screen and (max-width:1024px) {
    .listCouponsScene{
        background-size: cover;
        background-position: 50% 50%;
    }
}

@media screen and (max-width:902px) {
    .listCouponsScene{
        .couponList{
            margin-bottom: 0px;
        }
    }
}


@media screen and (max-width:420px) {
    .listCouponsScene{
        background-image: none;
        .couponList{
            .moreCoupon{
                display: none;
            }
        }
    }
}

@media screen and (max-width:320px) {
    .listCouponsScene{
        .couponList{
            margin: auto;
        }
        
    }
}