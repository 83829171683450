@use '../config/colors' as colors;
@use '../config/mixin' as mixin;

// Pour retirer les styles forcés de Safari Ios
textarea,
input.text,
input[type="text"],
input[type="button"],
input[type="submit"],
input[type="radio"],
input[type="checkbox"],
.input-checkbox {
-webkit-appearance: none;
-moz-appearance: none;
}

  .submitButton{
    width:84%;
    box-sizing: content-box;
    border: 0;
    border-radius: 50px 50px 50px 0;
    text-overflow: ellipsis;
    background-color: colors.$vertClair;
    color:#FFF;
    margin-left:8%;
    margin-top:20px;
    padding:10px 0 10px 0;
    font-family: 'Ubuntu';
    text-transform: uppercase;
    font-weight:700;
    font-size:1.3em;
    outline:none;
    cursor: pointer;
  }

  .rsLogin{
    margin:auto;
    width:100%;
    display:flex;
    flex-direction: column;
    max-width: 1200px;
    button{
      margin: auto;
      display: flex;
      background-color: transparent;
      width: 400px;
      box-sizing: content-box;
      border: 0;
      cursor: pointer;
    }
    .centrageBouton{
        width:70%;
        margin: 9px auto 0 auto;
        .facebookLogin{
          width:100%;
          height: 30px;
          padding-top: 12px;
          background-image:url('/media/images/form/loginFacebook.png');
          background-repeat: no-repeat;
          background-position: 5% 47%;
          background-color: colors.$facebookBlue;
          color: white;
          margin:auto;
          font-weight:700;
          font-size:1.2em;
          text-transform: uppercase;
          border: 0;
          border-radius: 50px 50px 50px 0;
      }
    }
    .subText{
        margin:auto;
        margin-top:10px;
        font-size:1.2em;
        text-transform: uppercase;
        color: colors.$textGrisClair;
    }
}

input[type="checkbox"]{
  visibility: hidden;
  margin-right:20px;
  vertical-align: bottom;
  &:hover{
    cursor: pointer;
  }
  &:after{
    width: 26px;
    height: 26px;
    border-radius:6px;
    top: 3px;
    position: relative;
    background: colors.$vertTresClair;
    content: '';
    display: inline-block;
    visibility: visible;
  }
  &:checked:after{
    width: 26px;
    height: 26px;
    border-radius:6px;
    top: 3px;
    content: "\2713";
    font-size: 22px;
    text-align: center;
    line-height: 22px;
    position: relative;
    background: colors.$vertTresClair;
    color: black;
    display: inline-block;
    visibility: visible;
  }
}



/// BUTTONS
.roundedButton{
  box-sizing: content-box;
  border: 0;
  border-radius: 50px 50px 50px 0;
  text-overflow: ellipsis;
  padding:10px;
  cursor: pointer;
}

// END BUTTONS

@media screen and (max-width:699px) {
  .rsLogin{
      button{
        width: 100%;
      }
      .centrageBouton{
        width: 90%;
      }
      .subText{
          font-size:0.85em;
      }
  }
  input[type='radio']{
    &:checked:after{
      line-height: 12px;
    }
  }
}

@media screen and (max-width:360px) {
  .rsLogin{
      .centrageBouton{
        .facebookLogin{
          font-size:1.1em;
        }
      }
      .subText{
          font-size:0.8em;
      }
  }
}