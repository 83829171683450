.formattedPrice{
    .big{
        font-size: 2.4em;
    }
    .medium{
        font-size: 1.7em;
    }
    .small{
        font-size: 1.3em;
    }
}