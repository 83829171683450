@use '../config/colors' as colors;

.updateUserScene{
    .updateForm{
        margin: 30px 0 20px 0;
        min-height: calc(100vh - 70px);
        width: 100%;
        .civilite{
            span{
                font-family: 'Ubuntu' !important;
                font-weight: 400;
                font-size: 1.3em !important;
                color: colors.$textGrisFonce;
                svg{
                    color: colors.$vertMoyen;
                }
            }
            
        }
        .dateNaissance{
            margin:10px 0 20px 0;
            width: 100%;
            label{
                font-weight: 400;
                color: colors.$vertClair;
                font-size: 1.3em;
                text-transform: uppercase;
                text-align: right;
            }
            input{
                font-family: 'Ubuntu';
                height:26px;
                font-weight: 400;
                font-size: 1.3em;
                color: colors.$textGrisFonce;
                border:0;
                background-color:transparent;
            }
            .birthdateValidated{
                margin-top:-4px;
                width: 100%;
                input{
                    font-family: 'Ubuntu';
                    height:26px;
                    font-weight: 400;
                    font-size: 1.2em;
                    color: colors.$textGrisFonce;
                    border:0;
                    background-color:transparent;
                }
            }
        }
        .radiusSelect{
            width: 100%;
            margin-top: -18px;
            label{
                font-weight: 400;
                color: colors.$vertClair;
                font-size: 1.3em;
                text-transform: uppercase;
                text-align: right;
            }
            input{
                font-family: 'Ubuntu';
                height:26px;
                font-weight: 400;
                font-size: 1.2em;
                color: colors.$textGrisFonce;
                border:0;
                background-color:transparent;
            }
            .selectLabel{
                font-weight: 400;
                font-size: 1.3em;
            }
            .selectedItem{
                & > div{
                    font-family: 'Ubuntu';
                    font-weight: 400;
                    font-size: 1.3em;
                    color: colors.$textGrisFonce;
                }
                li{
                    font-family: 'Ubuntu';
                    font-weight: 400;
                    font-size: 1.2em;
                    color: colors.$textGrisFonce;
                    &:hover{
                        background-color: colors.$vertClair;
                    }
                }
            }
        }
        .switchItem{
            margin: 10px 0 0 0;
            width: 100%;
            display:flex;
            justify-content: space-between;
            label{
                font-family: 'Ubuntu';
                font-weight: 400;
                color: colors.$vertClair;
                text-transform: uppercase;
                font-size: 1em !important;
            }
            & > span{
                font-family: 'Ubuntu';
                font-weight: 400;
                
            }
        }
        .cookieLink{
            font-weight: 400;
            color: colors.$vertClair;
            font-size: 1.05em;
            text-transform: uppercase;
            text-align: right;
        }
    }
}

@media screen and (max-width:699px) {
    .updateUserScene{
        .updateForm{
            .dateNaissance{
                label{
                    font-size: 1.3em;
                }
                input{
                    font-size: 1.3em;
                }
                .birthdateValidated,
                input{
                    font-size: 1.2em;
                }
            }
            .radiusSelect{
                .selectLabel{
                    font-size: 1.2em;
                }
                .selectedItem{
                    & > div{
                        font-size: 1.2em;
                    }
                }
            }
            .switchItem{
                & > span{
                    font-size: 1em !important;
                }
                label{
                    font-size: 1em !important;
                }
            }
        }
    }
  }

  @media screen and (max-width:360px) {
    .updateUserScene{
        .updateForm{
            .dateNaissance{
                label{
                    font-size:1.1em;
                }
                input{
                    font-size:1.1em;
                    }
                .birthdateValidated{
                    input{
                        font-size:1.1em;
                        }
                }
            }
            .radiusSelect{
                .selectLabel{
                    font-size: 1.1em;
                }
                .selectedItem{
                    & > div{
                        font-size: 1.1em;
                    }
                }
            }
            .switchItem{
                label{
                    font-size: 0.9em !important;
                }
            }
            .cookieLink{
                font-size: 0.9em;
            }
        }
    }
  }