@use '../config/colors' as colors;

.geolocBlock{
    width:90%;
    margin:-10px auto 10px auto;
    display: flex;
    justify-content: center;
    align-items: baseline;
    flex-flow: wrap;
    .icon-geolocsvg {
        width: 12px;
        height: 17px;
    }
    .geolocText{
        font-size: 16px;
        color: colors.$textGrisMoyen;
        font-weight:600;
        margin:0 8px 6px 8px;
    }
}
.askGeoloc{
    width:90%;
    margin:-10px auto 20px auto;
    display: flex;
    justify-content: center;
}