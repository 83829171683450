@use '../config/colors' as colors;
@use '../config/mixin' as mixin;

.safariPopin{
    width: 96%;
    height: 120px;
    background-color: colors.$vertSombre;
    border-radius: 20px;
    position: fixed;
    bottom: 20px;
    left:2%;
    display:flex;
    .logoPayps{
       background: url('/media/images/common/miniLogoPayps.png') 0 0 no-repeat;
        width:100px;
        height:87px;
        margin: -5px 0 0 12px;
        background-size: contain;
    }
    .textInstallation{
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1.4em;
        font-weight:500;
        color: white;
        text-align: left;
        width: 90%;
        //padding-top: 8px;
        align-self: center;
        .homeScreenIcon{
            margin-left:8px;
            vertical-align: text-top;
        }
    }
    .arrow{
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 20px 26px 0 26px;
        border-color: colors.$vertSombre transparent transparent transparent;
        position: fixed;
        bottom: 3px;
        left: 42%;
    }
}