@use '../config/colors' as colors;

.loginScene{
    margin:auto;
    display:flex;
    flex-direction: column;
    margin-top: -100px !important; /// Pour contrer le décalage sur toutes les autres scènes
    min-height: 103vh;
    
    .emailForm{
        width:100%;
        margin:auto;
        max-width: 1000px;
        padding-top: 12px;
        .captchaContainer{
            margin-left:8.5vw;
          }
        .forgotPassword{
            font-size: 0.9em;
            font-weight: 700;
            color: colors.$textGrisClair;
            text-align: right;
        }
    }
}
