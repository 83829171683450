.formContainer{
    display:flex;
    flex-direction: column;
    width:90%;
    margin:auto;
    max-width: 1000px;
    .captchaContainer{
        text-align: center;
        .centerCaptcha{
            display: inline-block;
        }
    }

    .acceptCgu{
        margin-top:40px;
        margin-bottom:40px;
        font-family: 'Ubuntu';
        font-weight: 300;
        font-size: 1.1em;
        color:#474747;
    }
}