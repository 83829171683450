@use '../config/colors' as colors;
@use '../config/mixin' as mixin;

.listGiftCardScene{
    justify-content: flex-start;
    .listGiftCardContainer{
        min-height: calc(100vh - 70px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .listTitle{
            font-size: 24px;
            font-weight: 600;
            color: colors.$vertSombre;
            margin: 30px auto 0 auto;
            width: 80%;
            text-align: center;
        }
        .noUserBlock{
            background-color: white;
            width: 90%;
            margin: 32px auto 0 auto;
            border-radius: 20px;
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
            .messageBlock{
                margin: 38px auto 20px auto;
                width: 70%;
                .messageText{
                    margin: auto;
                    text-align: center;
                    color: colors.$charcoalGrey;
                    font-family: 'Ubuntu';
                    font-size: 17px;
                    font-weight: 600;
                }
            }
            
            .bottomContainer{
                display:flex;
                flex-direction: row;
                justify-content: space-evenly;
                .connectButtons{
                    font-weight:700;
                    font-size: 20px;
                    text-transform: uppercase;
                    color: white;
                    text-align: center;
                    margin-top: 20px;
                    width: 70%;
                    order: 2;
                    align-self: center;
                    .mobileImage{
                        margin: 25px auto -5px auto;
                    }
                    .identificationBtn{
                        background-color: colors.$fondOrangeJaune;
                        box-shadow: 3px 6px 9px 0 rgba(0, 0, 0, 0.21);
                    }
                    a{
                        text-decoration: none;
                    }
                }
            }
        }
        .noCardsBlock{
            @include mixin.background-sizing('/media/images/giftCard/no_card.svg');
            width: 333px;
            height: 228px;
            margin:30px auto 30px auto;
            .noCardsText{
                width: 80%;
                color: colors.$charcoalGrey;
                font-weight:700;
                font-size:18px;
                text-align: center;
                margin: 74px auto 0 auto;
            }
        }
        .giftCardsListBlock{
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
            grid-auto-rows: auto;
            grid-gap: 10px;
        }
        .activateBlock{
            background-color: colors.$fondGrisClair;
            border-radius: 20px 20px 0 0;
            margin: 68px auto 0 auto;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            a{
                text-decoration: none;
            }
            .activateText{
                margin: 28px auto 20px auto;
                width: 80%;
                text-align: center;
                font-size: 18px;
                color: colors.$fondGrisSombre;
                font-size: 500;
                line-height: 22px;
            }
            .activateButton{
                background-color: colors.$fondOrangeJaune;
                width: 270px;
                height: 25px;
                color: white;
                margin: 0 auto 50px auto;
                font-weight:700;
                font-size:1.3em;
                text-transform: uppercase;
                display:flex;
                justify-content: center;
                align-items: center;
                .iconGiftCard{
                    width: 24px;
                    height: 24px;
                    margin-left: 13px;
                }
            }
        }
    }
    .notConnected{
        background: url('/media/images/giftCard/texture.svg') 0 repeat;
        background-position: top -290px left -180px;
        background-size: auto;
    }
}

@media screen and (max-width:420px) {
    .listGiftCardScene{
        .listGiftCardContainer{
            .noCardsBlock{
                background-size: contain;
                width: 84%;
            }
        }
    }
}

@media screen and (max-width:360px) {
    .listGiftCardScene{
        .listGiftCardContainer{
            .listTitle{
                margin: 20px auto 0 auto;
            }
            .noUserBlock{
                margin: 20px auto 0 auto;
                .messageBlock{
                    margin: 20px auto 20px auto;
                }
                
                .bottomContainer{
                    .connectButtons{
                        .mobileImage{
                            margin: 25px auto -6px auto;
                            img{
                                width: 66%;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width:320px) {
    .listGiftCardScene{
        .listGiftCardContainer{
            .giftCardsListBlock{
                grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
                grid-gap: 0px;
            }
        }
    }
}