@use '../config/mixin' as mixin;
@use '../config/colors' as colors;

$lightBlue: #00a2ee;
$darkBlue: #0073aa;
$lightGrey: #efefef;

.body_teampharma{
    background-color: $lightBlue !important;

    ///// COOKIE BOT
    a#CybotCookiebotDialogBodyLevelButtonAccept, 
    a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll,
    a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection{
        background-color: $lightBlue !important;
    }
    a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection{
        background-color:$lightBlue!important;
    }
    a#CybotCookiebotDialogBodyLevelButtonLevelOptinDeclineAll{
        background-color: $lightBlue !important;
    }

    #CybotCookiebotDialogNav{
        .active{
            color: $darkBlue !important;
            border-bottom: 1px solid $darkBlue !important;
        }
    }
    #CybotCookiebotDialog *, 
    #CybotCookiebotDialogBodyUnderlay *{
        .CybotCookiebotDialogDetailBodyContentCookieContainerButton{
            color: $darkBlue !important;
        }
    }

    #CybotCookiebotDialogBodyButtonsWrapper{
        .CybotCookiebotDialogBodyButton{
            background-color: $lightBlue !important;
        }
    }

    #CybotCookiebotDialogBodyEdgeMoreDetailsLink{
        color: $darkBlue !important;
    }
    .CybotCookiebotDialogActive{
        color: $darkBlue !important;
    }
  }

.teamPharmaContainer{
    // Menu + Header
    .sceneContainer{
        width:100vw;
        margin-top: 70px;
        .generalWrapper{
            .internalMessage{
                .textMessage{
                    margin: 26px auto 16px auto;
                }
            }
        }
    }
    .headerTeamPharma{
        position: fixed;
        top:-70px;
        width: 100vw;
        height: 74px;
        margin-top:70px;
        background-color: $lightBlue;
        z-index: 10;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // Define size for unclassed div containing menu Item
        & > :first-child{
            width: 85px;
        }
        .logoContainer{
            margin-top: 7px;
            cursor: pointer; 
        }
    }

    .legalsFooter{
        background: $lightBlue;
        margin-top: -1px;
    }
    .loginFooter{
        .bottomWave{
            background: $lightBlue;
        }
    }

    //// MENU
    .bm-menu {
        background: $darkBlue;
        padding: 2.5em 1.5em 0;
        font-size: 1.15em;
        .menuUserItem{
            background-color: $lightBlue;
            .blocEmail{
                color: $darkBlue;
            }
        }
        .paypsRs{
            color: $lightBlue;
        }
        //// ELEMENTS DU MENU LATERAL
        .menuListBr{
            @include mixin.background-menu("/media/images/teampharma/bonsDeReduction.png");
        }
        .menuListRetailers{
            @include mixin.background-menu("/media/images/teampharma/magasins.png");
        }
        .menuUserAccount{
            @include mixin.background-menu("/media/images/teampharma/monCompte.png");
        }
        .menuHowItWorks{
            @include mixin.background-menu("/media/images/teampharma/commentCaMarche.png");
        }
        .menuCarteClub{
            @include mixin.background-menu("/media/images/menu/ico_carteClub.svg");
          }
        .menuHomeScreen{
            @include mixin.background-menu("/media/images/teampharma/homeScreen.png");
        }
        .menuDisconnect{
            @include mixin.background-menu("/media/images/teampharma/disconnect.png");
        }
        .menuCreateAccount{
            @include mixin.background-menu("/media/images/teampharma/monCompte.png");
            margin-bottom:20px;
        }
        .menuQrCode{
            @include mixin.background-menu("/media/images/teampharma/icon-code-barre.svg");
            background-size: 30px 27px;
        }
        .menuListGiftCard{
            @include mixin.background-menu("/media/images/menu/ico_cartesCadeau.svg");
          }
    }
    .bm-cross{
        background-color: $darkBlue;
    }

    .logoHeader{
        display: flex;
        justify-content: center;
        .paypsLogo{
            width:226px;
            height: 174px;
            margin-top: 26px;
            mix-blend-mode: multiply;
        }
    }

    @media screen and (max-width:699px) {
        .logoHeader{
            margin-top: 12px;
            .paypsLogo{
                width:184px;
                height: 142px;
            }
        }
    }

    @media screen and (max-width:400px) {
        .logoHeader{
            .paypsLogo{
                width:140px;
                height: 108px;
            }
        }
    }

    // Common
    .titleRubrique{
        color: $darkBlue;
        margin:34px auto 24px auto;
    }

    .submitButton{
        background-color: $lightBlue;
    }

    .inputLine label{
        color: $lightBlue;
    }

    .loadingItem div {
        background: $darkBlue;
    }

    .priceBlock{
        .productInfo{
        .couponValue{
            color: $darkBlue;
        }
        }
    }

    .returnButton{
        margin-left: -1px;
    }

    .backButton{
        background-color: $darkBlue;
    }

    .economyFooter{
        margin-bottom: -1px;
        .orangeBg{
            background-color: $lightBlue;
        }
        .footerBg{
            @include mixin.background-sizing-svg("/media/images/teampharma/footer-bg.svg");
        }
    }

    @media screen and (max-width:380px) {
        .economyFooter{
            .footerBg{
                .topLine{
                    .texteEconomy{
                        font-size: 14px;
                    }
                    .valueEconomy{
                        font-size: 14px;
                    }
                }
            }
        }
    }
    @media screen and (max-width:320px) {
    .economyFooter{
            .footerBg{
                //height: 89px;
                .topLine{
                    .texteEconomy{
                        font-size: 13px;
                    }
                    .valueEconomy{
                        font-size: 13px;
                    }
                }
            }
        }
    }

    // HomePage
    .homeContainer{
        .greyContainer{
            .claimPayps{
                .claimTop{
                    color: $darkBlue !important;
                }
                .claimBottom{
                    color: $lightBlue !important;
                }
            }
            .buttonLine{
                .registerBloc{
                    background-image: none;
                    background-color: white;
                    //height: 328px;
                    .textProfiter{
                        color: colors.$textGris;
                    }
                    .or{
                        background-color: #f5f5f5;
                        color: colors.$textGris;
                    }
                }
                .secondLine{
                    width: inherit;
                    .assurance{
                        .assuranceDescription{
                            color: $darkBlue !important;
                        }
                        .textGratuit{
                            color: $lightBlue !important;
                        }
                    }
                }
            }
        }
    }

    .loginScene{
        background-color: $lightGrey;
    }

    @media screen and (max-width:699px) {
        .loginScene{
            .logoHeader{
                margin-top: 40px;
            }
        }
    }

    // CreateUser
    .createUserScene{
        background-color: $lightGrey;
        .updateForm{
            .civilite{
                span{
                    svg{
                        color: $darkBlue;
                    }
                }
            }
            .dateNaissance{
                label{
                    color: $lightBlue;
                }
            }
            .cguBlock{
                a{
                    color: $darkBlue;
                }
            }
        }
    }
    @media screen and (max-width:699px) {
        .createUserScene{
            .logoHeader{
                margin-top: 40px;
            }
        }
    }

    // Password Update
    .passwordUpdateScene{
        background-color: $lightGrey;
        padding-top: 50px;
    }

    // UpdateUser
    .updateUserScene{
        background-color: $lightGrey;
        padding-top: 50px;
        .updateForm{
            .civilite{
                span{
                    svg{
                        color: $darkBlue;
                    }
                }
            }
            .dateNaissance{
                label{
                    color: $lightBlue;
                }
            }
            .radiusSelect{
                label{
                    color: $lightBlue;
                }
            }
            .switchItem{
                label{
                    color: $lightBlue;
                }
            }
            .cookieLink{
                color: $lightBlue;
            }
            .cguBlock{
                a{
                    color: $darkBlue;
                }
            }
        }
    }

    // Registration Needed
    .registrationNeededScene{
        padding-top: 30px;
        .message{
            .message_text{
                color: $darkBlue;
            }
            .bottomContainer{
                .connectButtons{
                    .or{
                        background-color: $lightBlue;
                    }
                }
            }
        }
        .clientBloc{
            background-image: linear-gradient(179deg, $darkBlue 42%, $lightBlue 100%);
            .lines{ 
                @include mixin.background-sizing-svg("/media/images/teampharma/bg_illustr-blue.svg");
                background-position: 0 36px;
                background-size: contain;
                padding-top: 1px;
                .clientImage{
                    @include mixin.background-sizing("/media/images/teampharma/illust_perso.png");
                    margin: auto;
                    width: 243px;
                    height: 383px;
                }
            }
        }
    }

    @media screen and (max-width:980px) {
        .registrationNeededScene{
            .clientBloc{
                width: 392px;
                height: 439px;
                .lines{ 
                    background-size: cover;
                    background-position: -330px -78px;
                }
            }
        }
    }

    @media screen and (max-width:500px) {
        .registrationNeededScene{
            .message{
                width: 100%;
            }
            .clientBloc{
                width: 86%;
                .lines{
                    background-position: -330px -73px;
                    .clientTitle{
                        font-size: 24px;
                    }
                }
            }
        }
    }

    /// List coupons scene
    .listCouponsScene{
        background: none;
        background-color: $lightGrey;
        .teamPharmaBannerContainer{
            width: 376px;
            margin: 0 2% 0 2%;
            .teamPharmaBanner{
                display:flex;
                flex-direction: column;
                align-items: center;
                margin: 12px 0;
                img{
                    width: 94%;
                }
            }
        }
    }
    @media screen and (max-width:600px) {
        .listCouponsScene{
            .teamPharmaBannerContainer{
                width: 100vw;
            }
        }
    }

    // Cart scene
    .cartScene{
        background: none;
        background-color: $lightGrey;
        .economyBlock{
            background-color:$darkBlue;
            .cartExtraHeader{
                background-color: $lightBlue;
                color: white;
                font-size: 28px;
                font-weight: 700;
                text-transform: uppercase;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .staticBlock{
                    height: 50px;
                    width: 100%;
                    position: fixed;
                    top:70px;
                    z-index: 9;
                    background-color: $lightBlue;
                }
                .textHeaderCart{
                    margin: 70px auto 20px auto;
                }
            }
            .economyTitle{
                font-weight: 500;
            }
        }
        .showFullPayps{
            width: 100%;
            background-color: $lightBlue;
            display:flex;
            flex-direction: column;
            align-items: center;
            .showPaypsText{
                margin-top: 24px;
                width: 72%;
                color: white;
                font-size: 19px;
                text-align: center;
            }
            .accepterBtn{
                margin: 18px auto 50px auto;
                font-size: 14px;
                padding: 16px 30px;
            }
        }
    }

    .askPasswordScene{
        background: none;
        background-color: $lightGrey;
    }

    // Coupon details scene
    .couponDetailsScene{
        background: none;
        background-color: $lightGrey;
        .habillageDefault{
            margin-bottom: 0px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .titleBloc{
                .titleCoupon{
                    color: $darkBlue;
                }
            }
            .imageHabillage{
                margin-bottom: -1px;
                width:100%;
                max-width: 376px;
            }
        }
    }

    @media screen and (max-width:450px) {
        .couponDetailsScene{
            .habillageDefault{
                .imageHabillage{
                    width:100vw;
                    max-width: none;
                }
            }
        }
    }

    .couponDetailsItem{
        .descriptionBlock{
            .modalite{
                color: $darkBlue;
            }
        }
    }

    /// Retailers List
    .retailersScene{
        .headerList{
            background-color: $lightGrey;
            .titleSmallMargin{
                margin: 54px auto 0px auto;
            }
        }
        .listContainer{
            .retailersList{
                .retailerItem{
                    .retailerInfo{
                        .retailerData{
                            .retailerName{
                                color: $darkBlue;
                            }
                            .clickCollect{
                                background-color: $lightBlue;
                            }
                        }
                        .total{
                            @include mixin.background-no-sizing-svg("/media/images/teampharma/bg_coupon.svg");
                        }
                    }
                }
            }
        }
    }

    // Check Product scene
    .checkProductScene{
        background: none;
        background-color: $lightGrey;
        padding-top: 50px;
    }

    /// FAQ  
    .didacticielScene{
        background-color: $lightGrey;
        padding-top: 50px;
        .casherBlock{
            border-bottom: solid 4px $darkBlue;
        }
        .faqContainer{
            .Collapsible{
                border-bottom: solid 4px $darkBlue;
                .faqTitle{
                    color: $lightBlue;
                }
                .titleClose{
                    @include mixin.background-no-sizing-svg("/media/images/teampharma/flecheRevert.svg");
                    background-repeat: no-repeat;
                    background-position-x: right;
                    padding: 0 0 0 12px;
                } 
                .titleOpen{
                    @include mixin.background-no-sizing-svg("/media/images/teampharma/fleche.svg");
                    background-repeat: no-repeat;
                    background-position-x: right;
                    padding: 0 0 0 12px;
                }
                .faqDescription{
                    ul{
                        li{
                            .listItem{
                                color: $lightBlue;
                            }
                        }
                    }
                }
            }
        }
        .slickContainer{
            .slideItem{
                .slideTitle{
                    color: $lightBlue;
                }
            }
        }
    }

    ///RGPD scenes
    .rgpdFilesScene{
        background-color: $lightGrey;
        padding-top: 50px;
        .texte{
            a,
            .clickLink{
                color: $darkBlue;
            }
            h2{
                color: $darkBlue;
            }
            h3{
                color: $darkBlue;
            }
        }
    }

    // External coupns details
    .externalCouponDetailsScene{
        background-color: $lightGrey;
        .couponList{
            background-color: $lightGrey;
            .couponContainer{
                .topBloc{
                    .topProductBloc{
                        .externalTitle{
                            color: $darkBlue;
                        }
                        .couponHeader{
                            .productPrice{
                                .couponValue{
                                    color: $darkBlue;
                                }
                            }
                        }
                    }
                    .noAccount{
                        background-color: $darkBlue;
                        .noTitle{
                            color: $lightBlue;
                        }
                        .inscrit_btn{
                            background-color: $lightBlue;
                        }
                    }
                }
                .bottomBloc{
                    .couponDetailsBloc{
                        .modalite{
                            color: $darkBlue;
                            font-size: 15px;
                            font-weight: 700;
                            text-transform: uppercase;
                            margin: 12px 14px 12px 14px;
                        }
                    }
                }
            }
        }
    }

    .showGetPaypsWindow{
        width:100%;
        height: 100%;
        background-color:rgba(0, 0, 0, 0.7);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1009;
        .showPaypsBlock{
            width: 84%;
            background-color: $lightBlue;
            border-radius: 20px;
            position: fixed;
            top: 100px;
            left:8%;
            display:flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            .topBlock{
                font-size: 20px;
                font-weight: 700;
                color: white;
                width:88%;
                margin: 30px auto;
            }
            .bottomBlock{
                background-color: $darkBlue;
                border-radius: 0 0 20px 20px;
                .showPaypsText{
                    margin: 24px auto 0 auto;
                    width: 72%;
                    color: white;
                    font-size: 16px;
                    text-align: center;
                }
                .accepterBtn{
                    margin: 18px auto 18px auto;
                    font-size: 14px;
                    padding: 16px 30px;
                    width: 120px;
                }
                .refuseLink{
                    color: white;
                    text-decoration: underline;
                    margin-bottom: 30px;
                }
            }
        }
    }
}

.safariPopin{
    background-color: $darkBlue;
    .logoPayps{
        background: none;
        width: 4%;
    }
    .textInstallation{
        .homeScreenIcon{
            mix-blend-mode: luminosity;
        }
    }
    .arrow{
        border-color: $darkBlue transparent transparent transparent;
    }
}

.showQrCodeScene{
    background: none;
    background-color: $lightGrey;
}