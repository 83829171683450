@use '../config/colors' as colors;
@use '../config/mixin' as mixin;

.showQrCodeScene{
    margin:auto;
    @include mixin.backgroundScene();
    .qrCodeContainer{
        display:flex;
        flex-direction: column;
        justify-content: flex-start;
        height: calc(100vh - 70px);
        .useDescription{
            width: 70%;
            font-size: 16px;
            font-weight: 600;
            color: rgba(70, 70, 70, 0.8);
            margin: 20px auto;
            text-align: center;
        }
        .qrCode{
            display: block;
            margin: 20px auto 30px auto;
        }
        .barcodeContainer{
            display: flex;
            justify-content: center;
            margin: auto;
            width: 100vw;
            .barcode{
                margin: auto;
                display: block;
                text-align: center;
            }
            .fullscreen{
                transform: rotate(270deg);
                transform-origin: center;
            }
        }
    }
    .noFull{
        margin:30px auto 0 auto;
        //min-height: 640px;
        min-height: calc(100vh - 70px);
    }
}

@media screen and (max-width:1024px) {
    .showQrCodeScene{
        background-size: cover;
        background-position: 50% 50%;
    }
}

@media screen and (max-width:420px) {
    .showQrCodeScene{
        background-image: none;
        background-color: colors.$fondRose;
    }
}

@media screen and (max-width:320px) {
    .showQrCodeScene{
        .qrCodeContainer{
            height: 560px;
        }
    }
}

@media screen and (orientation: landscape) {
    .showQrCodeScene{
        .qrCodeContainer{
            .barcodeContainer{
                .fullscreen{
                    transform: rotate(0deg);
                    transform-origin: center;
                }
            }
        }
    }
  }