@use '../config/colors' as colors;
@use '../config/mixin' as mixin;

.retailersFilter{
    display: block;
    .filterContainer{
        margin: -167px 0 10px -270px;
        .filterBox{
            background-color: white;
            width: 290px;
            height: 137px;
            border-radius: 22px 22px 0 22px;
            box-shadow: 1px 3px 12px 0 rgba(0, 0, 0, 0.31);
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            .filterTextBlock{
                background-color: colors.$textGris;
                color: white;
                font-size: 12px;
                font-weight: 500;
                border-radius: 20px;
                width: 262px;
                height: 34px;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                .filterIcon{
                    margin-left: 15px;
                }
            }
            .discountTextDisabled,
            .geolocTextDisabled{
                background-color: colors.$textGrisTresClair;
            }
        }
        .downArrow{
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 20px 36px 20px 0;
            border-color: transparent white transparent transparent;
            margin: -20px 0 0 254px;
        }
    }
    .show{
        visibility:visible;
    }
    .hide{
        visibility: hidden;
    }
    .selectFilter{
        margin-top: -5px;
    }
    .geoloc{
        @include mixin.background-sizing-svg("/media/images/map/ico_filters_geo.svg");
        width: 44px;
        height: 44px;
    }
    .geolocDisabled{
        @include mixin.background-sizing-svg("/media/images/map/ico_filters_geo_disabled.svg");
        width: 44px;
        height: 44px;
    }
    .discount{
        @include mixin.background-sizing-svg("/media/images/map/ico_filters_discount.svg");
        width: 44px;
        height: 44px;
    }
    .discountDisabled{
        @include mixin.background-sizing-svg("/media/images/map/ico_filters_discount_disabled.svg");
        width: 44px;
        height: 44px;
    }
}
.retailersFilterHide{
    display:none;
}
.extraResults{
    background-color: white;
    margin: auto;
    .nextButton{
        width: 256px;
        height: 39px;
        border-radius: 44px;
        background-color: #f5f5f5;
        margin: 30px auto 30px auto;
        box-shadow: 1px 3px 12px 0 rgba(0, 0, 0, 0.26);
        color: colors.$textGris;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        line-height: 9px;
        cursor: pointer;
    }
}

@media screen and (max-width:320px) {
    .retailersFilter{
        .filterContainer{
            margin: -167px 0 10px -250px;
            .filterBox{
                width: 280px;
                .filterTextBlock{
                    font-size: 11px;
                    width: 252px;
                }
            }
            .downArrow{
                margin: -20px 0 0 244px;
            }
        }
    }
}