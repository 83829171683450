@use '../config/colors' as colors;

.passwordUpdateScene{
    margin:auto;
    display:flex;
    flex-direction: column;
    min-height:calc(100vh - 100px);
    .passwordForm{
        width:90%;
        margin:auto;
        margin-top:50px;
        .cguBlock{
            text-align: center;
            margin-top: 6px;
            a{
                color: colors.$vertSombre;
            }
        }
    }
}

@media screen and (max-width:360px) {
    .passwordUpdateScene{
        .passwordForm{
            .passwordManager{
                .submitButton{
                    font-size:1.2em;
                }
            }
        }
    }
}